import "../../../style/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { AuthContext } from "../../../branch/context/AuthContext";
import { useContext } from "react";
import { base_url } from "../../../api/Axios";
const MyAccountsDataTable = () => {
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [myAccountData, setMyAccountData] = useState("");
  useEffect( () => {
    async function fetchData() {
      const response = await axios.get(`${base_url}users/account`, {
        headers: { Authorization: `Bearer ${token.token}` },
      });
       setMyAccountData(response.data);
    }
    fetchData()
  });
  // console.log(myAccountData);
  let i = 0;
  const listOfAccounts = [
    {
      id: Number(++i),
      account_no: myAccountData.account_no,
      balance: myAccountData.balance,
      full_name: myAccountData.full_name,
      group: myAccountData.group,
      level: myAccountData.level,
      profile: myAccountData.profile,
      phone: myAccountData.phone,
      url: myAccountData.url,
      status: myAccountData.status,
      services: myAccountData.services,
    },
  ];
  // console.log(listOfAccounts);
  const handleDelete = (id) => {};
  const userColumns = [
    // { field: "id", headerName: "ID", width: 70 },
    {
      field: "account_no",
      headerName: "Account Number",
      width: 150,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      width: 150,
    },
    {
      field: "full_name",
      headerName: "Full Name",
      width: 150,
    },

    // {
    //   field: "group",
    //   headerName: "Group",
    //   width: 70,
    // },
    {
      field: "profile",
      headerName: "Profile",
      width: 100,
    },
    {
      field: "level",
      headerName: "Level",
      width: 100,
    },
    {
      field: "balance",
      headerName: "Balance",
      width: 100,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`}>
            {params.row.status}
          </div>
        );
      },
    },
  ];
  const viewHandler = (services) => {
    navigate("/branch/accounts/services", { state: { service: services } });
  };
  const actionColumn = [
    // {
    //   field: "action",
    //   headerName: "Services",
    //   width: 100,
    //   renderCell: (params) => {
    //     return (
    //       <div className="cellAction">
    //         <div
    //           className="viewButton"
    //           onClick={() => viewHandler(params.row.services)}
    //         >
    //           <VisibilityIcon />
    //         </div>
    //       </div>
    //     );
    //   },
    // };
  ];
  return (
    <div className="datatable">
 
      <DataGrid
        className="datagrid"
        rows={listOfAccounts}
        columns={userColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
        sx={{
          boxShadow: 2,
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
      />
    </div>
  );
};

export default MyAccountsDataTable;
