import "../../../style/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { base_url } from "../../../api/Axios";
import FormControl from "@mui/material/FormControl";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button } from "@mui/material";
const TransfersDataTable = () => {
  const [transactionData, setTransactionData] = useState([]);
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [pageSize, setPageSize] = useState(10);
  const [reference_id, setReferenceId] = useState("");
  const [typeOfTransaction, setTypeOfTransactions] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  useEffect(() => {
    (async () => {
      const response = await axios.get(`${base_url}transfers?page_size=200`, {
        headers: { Authorization: `Bearer ${token.token}` },
      });
      setTransactionData(response.data.results);
      console.log(transactionData)
    })();
  }, []);

  const filterHandler = async () => {
    const response = await axios.get(
      `${base_url}transfers?reference_id=${reference_id}&type=${typeOfTransaction}&after=${startTime}&before=${endTime}&page_size=all`,
      {
        headers: { Authorization: `Bearer ${token.token}` },
      }
    );
    setTransactionData(response.data);
    // console.log(transactionData)
  };
 
  const listOfTransaction = transactionData.map((transaction) => {
    return {
      id: transaction.id,
      payer: transaction.payer,
      payer_name: transaction.payer_name,
      payer_phone: transaction.payer_phone,
      payer_account: transaction.payer_account,
      payee: transaction.payee,
      payee_name: transaction.payee_name,
      payee_phone: transaction.payee_phone,
      payee_account: transaction.payee_account,
      process: transaction.process,
      balance: transaction.balance,
      amount: transaction.amount,
      type: transaction.type === "DEBIT" ? "-" : "+",
      fee: transaction.fee,
      description: transaction.description,
      status: transaction.status,
      reason: transaction.reason,
      updated_at: new Date(transaction.updated_at).toLocaleString(),
    };
  });

  const userColumns = [
    {
      field: "id",
      headerName: "Transaction ID",
      width: 120,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.id}</div>;
      },
    },
    // {
    //   field: "payer",
    //   headerName: "Payer",
    //   width: 130,
    //   renderCell: (params) => {
    //     return <div className={`cellsWithFields `}>{params.row.payer}</div>;
    //   },
    // },
    {
      field: "payer_name",
      headerName: "From",
      width: 110,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.payer_name}</div>
        );
      },
    },

    {
      field: "payee_name",
      headerName: "To",
      width: 110,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.payee_name}</div>
        );
      },
    },
    {
      field: "process",
      headerName: "Process",
      width: 130,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.process}</div>;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 90,
      renderCell: (params) => {
        return (
          <div className={`cellWithType ${params.row.type}`}>
            {params.row.type} {params.row.amount}
          </div>
        );
      },
    },

      {
      field: "fee",
      headerName: "Fee",
      width: 90,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.fee}</div>;
      },
    },
 
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`}>
            {params.row.status}
          </div>
        );
      },
    },
    {
      field: "balance",
      headerName: "Balance",
      width: 100,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.balance}`}>
            {params.row.balance}
          </div>
        );
      },
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      width: 170,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.updated_at}</div>
        );
      },
    },
  ];
  const detailHandler = (transfers) => {
    console.log(transfers);
    navigate("/branch/transfers/transferDetail", {
      state: { transfer: transfers },
    });
  };
  const actionColumn = [
    {
      field: "Detail",
      headerName: "Detail",
      width: 70,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="viewButton"
              onClick={() => detailHandler(params.row)}
            >
              <VisibilityIcon />
            </div>
          </div>
        );
      },
    },
  ];
  // console.log(pageSize)
  const theme = createTheme();
  return (
    <div className="datatable">
      {/* <div className="datatableTitle">Transactions</div> */}
      <div className="accountFilter">
        <ThemeProvider theme={theme}>
          <Container component="main">
            <div className="formInput">
              <label>Transaction ID</label>
              <input
                type=" Transfer ID"
                placeholder="Enter Transfer ID"
                value={reference_id}
                onChange={(e) => setReferenceId(e.target.value)}
                style={{
                  // backgroundColor: "#ffcc00",
                  color: "#000",
                  fontSize: 15,
                  width: "100%",

                  height: 25,
                  marginTop: 10,
                }}
              />
            </div>
          </Container>
          <Container component="main">
            <div className="formInput">
              <label className="label">Type Transaction</label>
              <select
                className="formInput"
                name="active"
                id="active"
                value={typeOfTransaction}
                onChange={(e) => setTypeOfTransactions(e.target.value)}
                style={{
                  // backgroundColor: "#ffcc00",
                  color: "#000",
                  fontSize: 15,
                  width: "100%",
                  marginTop: 10,
                  height: 30,
                }}
              >
                <option value="">Type of Transaction</option>
                <option value="DEBIT">Debited</option>
                <option value="CREDIT">Credited</option>
              </select>
            </div>
          </Container>

          <Container component="main">
            <div className="formInput">
              <label className="label">From</label>
              <input
                // type="From"
                placeholder="From"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                type="date"
                style={{
                  // backgroundColor: "#ffcc00",
                  color: "#000",
                  fontSize: 15,
                  width: "100%",

                  height: 25,
                  marginTop: 10,
                }}
              />
            </div>
          </Container>
          <Container component="main">
            <div className="formInput">
              <label className="label">To</label>

              <input
                type="date"
                placeholder="to"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                // type="date"
                style={{
                  // backgroundColor: "#ffcc00",
                  color: "#000",
                  fontSize: 15,
                  width: "100%",

                  height: 25,
                  marginTop: 10,
                }}
              />
            </div>
          </Container>
        </ThemeProvider>
        <div className="filterButton">
          <Container component="main">
            <FormControl fullWidth sx={{ m: 1 }}>
              <Button
                type="submit"
                onClick={filterHandler}
                variant="contained"
                // sx={{ mt: 1, mb: 1 }}
              >
                Submit
              </Button>
            </FormControl>
          </Container>
        </div>
      </div>
      <DataGrid
        rowHeight={40}
        className="datagrid"
        rows={listOfTransaction}
        columns={userColumns.concat(actionColumn)}
        pageSize={pageSize}
        
        onPageSizeChange={(newPageSize) => {setPageSize(newPageSize)}}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        pagination
        checkboxSelection
        sx={{
          boxShadow: 2,
          // border: 2,
          // borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
      />
    </div>
  );
};

export default TransfersDataTable;
