import "../../../style/datatable.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { base_url } from "../../../api/Axios";
import * as React from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const AuthorizeTransactions = () => {
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [pin, setPin] = useState("");
  const [pinError, setPinError] = useState("");
  const location = useLocation();
  const url = location.state.url;
  console.log(`${base_url}transactions/${url}/authorize`);
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${base_url}transactions/${url}/authorize`,
        { pin },
        {
          headers: { Authorization: `Bearer ${token.token}` },
        }
      );
      toast.success(`You have Authorized!`);
      navigate("/branch/transactions");
    } catch (error) {
      setPinError(error.response.data[0]);
    }
  };
  const theme = createTheme();
  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar title={"Authorize Transaction"} />
        <div className="top">
          <h1>Authorize Transaction</h1>
        </div>
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box component="form" onSubmit={submitHandler} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Pin "
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  autoFocus
                  inputProps={{ maxLength: 4, minLength: 4 }}
                  value={pin}
                  onChange={(e) => setPin(e.target.value)}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Authorize Transaction
                </Button>
                {pinError && <p style={{ color: "red" }}>{pinError}</p>}
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div>
    </div>
  );
};
export default AuthorizeTransactions;
