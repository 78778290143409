import "../../../style/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { base_url } from "../../../api/Axios";
const OutletsDataTable = () => {
  const [outletsData, setOutletsData] = useState([]);
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [currentURL, setCurrentURL] = useState("");
  const [action, setAction] = useState("");
  const [pageSize, setPageSize] = useState(10);
  useEffect(() => {
    (async () => {
      const response = await axios.get(`${base_url}outlets?page_size=1000`, {
        headers: { Authorization: `Bearer ${token.token}` },
      });

      // const usersList = response.data.data.users;
      setOutletsData(response.data.results);
      console.log(response.data.results);
    })();
  }, []);
  let i = 0;
  const listOfOutlets = outletsData.map((outlet) => {
    return {
      id: Number(++i),
      phone: outlet.phone,
      business_name: outlet.business_name,
      type: outlet.type,
      head_office: outlet.head_office,
      short_name: outlet.short_name,
      owner_name: outlet.owner_name,
      owner_phone: outlet.owner_phone,
      short_code: outlet.short_code,
      url: outlet.url,
      moa: outlet.moa,
      aoa: outlet.aoa,
      license: outlet.license,
      tin: outlet.tin,
      no_employee: outlet.no_employee,
      code: outlet.code,
      level: outlet.level,
      status: outlet.status,
      address: outlet.address,
    };
  });

  const userColumns = [
    // { field: "id", headerName: "ID", width: 70 },
    {
      field: "phone",
      headerName: "Phone Number",
      width: 130,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.phone}</div>;
      },
    },
    {
      field: "business_name",
      headerName: "Business Name",
      width: 140,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.business_name}</div>
        );
      },
    },
    {
      field: "type",
      headerName: "Business Type",
      width: 130,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.type}</div>;
      },
    },
    {
      field: "short_name",
      headerName: "Short Name",
      width: 90,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.short_name}</div>
        );
      },
    },
    {
      field: "owner_name",
      headerName: "Owner Name",
      width: 140,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.owner_name}</div>
        );
      },
    },
    {
      field: "owner_phone",
      headerName: "Owner Phone",
      width: 120,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.owner_phone}</div>
        );
      },
    },
    {
      field: "code",
      headerName: "Code",
      width: 90,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.code}</div>;
      },
    },
  ];
  const detailHandler = (businesses) => {
    console.log(businesses);
    navigate("/branch/outlets/outletsDetail", {
      state: { business: businesses },
    });
  };

  // const detailHandler = (agents) => {
  //   console.log(agents);
  //   navigate("/branch/businesses/businessDetail", {
  //     state: { business: agents },
  //   });
  // };


  const actionHandler = (e) => {
    e.preventDefault();
    console.log("from", action);

    if (currentURL)
      navigate(`/${action}`, {
        state: {
          url: currentURL.split("/")[5],
          action: action,
          category: "outlets",
        },
      });
  };

  const actionColumn = [
    {
      field: "Detail",
      headerName: "Detail",
      width: 60,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="viewButton"
              onClick={() => detailHandler(params.row)}
            >
              <VisibilityIcon />
            </div>
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 160,
      renderCell: (params) => {
        return (
          <div className="formInput">
            <select
              className="formInput"
              name="active"
              id="active"
              // value={currentURL}
              onChange={(e) => {
                setAction(e.target.value);
                setCurrentURL(params.row.url);
              }}
              onClick={actionHandler}
              style={{
                backgroundColor: "#ffcc00",
                color: "#000",
                fontSize: 15,
              }}
            >
              {/* <option>External Actions</option> */}
              <option value="activity_log">Activity Log</option>
              <option value="change_status">Change Status</option>
              <option value="specificStatement">Statement</option>
              {/* <option value="mini_statement">Mini Statement</option> */}
              {/* <option value="specificReconciliation">Reconciliation</option> */}
              <option value="specificSummary">Summary</option>
              {/* <option value="specificSummaryByDate">Summary by Date</option> */}
              <option value="transfers">transactions</option>
              <option value="operators">operators</option>
              <option value="specificCommissions">commissions</option>
            </select>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      {/* <div className="datatableTitle">
        Outlets List
        <Link to="/branch/outlets/newOutlets" className="link">
          <AddIcon />
        </Link>
      </div> */}
      <DataGrid
        rowHeight={40}
        className="datagrid"
        rows={listOfOutlets}
        columns={userColumns.concat(actionColumn)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        checkboxSelection
        sx={{
          boxShadow: 2,
          // border: 2,
          // borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
      />
    </div>
  );
};

export default OutletsDataTable;
