import "../../style/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../branch/context/AuthContext";
import { useContext } from "react";
import { base_url } from "../../api/Axios";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import { useLocation } from "react-router-dom";
const SpecificTransactions = () => {
  const [transactionData, setTransactionData] = useState([]);
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const location = useLocation();
  const url = location.state.url;
  const action = location.state.action;
  const category = location.state.category;
  const [pageSize, setPageSize] = useState(10);
// console.log(`${base_url}${category}/${url}/${action}`)
  useEffect(() => {
    (async () => {
      const response = await axios.get(
        `${base_url}${category}/${url}/transactions?page_size=all`,
        {
          headers: { Authorization: `Bearer ${token.token}` },
        }
      );
      // console.log(`${base_url}${category}/${url}/transactions?page_size=200`);
      // const usersList = response.data.data.users;
      // console.log(`${base_url}${category}/${url}/transactions?page_size=all`)
      // console.log(response)
      setTransactionData(response.data);
    })();
  }, []);
  // console.log(transactionData);
  const listOfTransaction = transactionData.map((transaction) => {
    return {
      id: transaction.id,
      payer: transaction.payer,
      payer_name: transaction.payer_name,
      payer_phone: transaction.payer_phone,
      payer_account: transaction.payer_account,
      payee: transaction.payee,
      payee_name: transaction.payee_name,
      payee_phone: transaction.payee_phone,
      payee_account: transaction.payee_account,
      process: transaction.process,
      balance: transaction.balance,
      amount: transaction.amount,
      type: transaction.type === "DEBIT" ? "-" : "+",
      fee: transaction.fee,
      description: transaction.description,
      status: transaction.status,
      reason: transaction.reason,
      updated_at: new Date(transaction.updated_at).toLocaleString(),
    };
  });
  const userColumns = [
    {
      field: "id",
      headerName: "Transaction ID",
      width: 120,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.id}</div>;
      },
    },
    // {
    //   field: "payer",
    //   headerName: "Payer",
    //   width: 130,
    //   renderCell: (params) => {
    //     return <div className={`cellsWithFields `}>{params.row.payer}</div>;
    //   },
    // },
    {
      field: "payer_name",
      headerName: "From",
      width: 110,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.payer_name}</div>
        );
      },
    },

    {
      field: "payee_name",
      headerName: "To",
      width: 110,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.payee_name}</div>
        );
      },
    },
    {
      field: "process",
      headerName: "Process",
      width: 130,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.process}</div>;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 90,
      renderCell: (params) => {
        return (
          <div className={`cellWithType ${params.row.type}`}>
            {params.row.type} {params.row.amount}
          </div>
        );
      },
    },

      {
      field: "fee",
      headerName: "Fee",
      width: 30,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.fee}</div>;
      },
    },
 
    // {
    //   field: "reason",
    //   headerName: "Reason",
    //   width: 130,
    //   renderCell: (params) => {
    //     return (
    //       <div className={`cellWithStatus ${params.row.reason}`}>
    //         {params.row.reason}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "balance",
      headerName: "Balance",
      width: 100,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.balance}`}>
            {params.row.balance}
          </div>
        );
      },
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      width: 170,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.updated_at}</div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`}>
            {params.row.status}
          </div>
        );
      },
    },
  ];
  const detailHandler = (transactions) => {
    // console.log(transactions);
    navigate("/specificTransactionDetail", {
      state: { transaction: transactions },
    });
  };
  const actionColumn = [
    {
      field: "Detail",
      headerName: "Detail",
      width: 70,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="viewButton"
              onClick={() => detailHandler(params.row)}
            >
              <VisibilityIcon />
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar title={"Transactions"}/>
        <div className="datatable">
          {/* <div className="datatableTitle">Transactions</div> */}
          <DataGrid
        rowHeight={40}
        className="datagrid"
        rows={listOfTransaction}
        columns={userColumns.concat(actionColumn)}
        pageSize={pageSize}
        
        onPageSizeChange={(newPageSize) => {setPageSize(newPageSize)}}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        pagination
        checkboxSelection
        sx={{
          boxShadow: 2,
          // border: 2,
          // borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
      />
        </div>
      </div>
    </div>
  );
};

export default SpecificTransactions;
