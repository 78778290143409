import "./widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { useEffect, useState } from "react";
import { AuthContext } from "../../branch/context/AuthContext";
import { useContext } from "react";
import { base_url } from "../../api/Axios";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
const Widget = ({ type }) => {
  let data;

  //temporary
  const diff = 20;
  const { token } = useContext(AuthContext);
  const {role} = useContext(AuthContext)
  const [numberOfUsers, setNumberOfUsers] = useState("");
  const [numberOfAccount, setNumberOfAccount] = useState("");
  const [numberOfCustomers, setNumberOfCustomers] = useState("");
  const [numberOfAgents, setNumberOfAgents] = useState("");
  const [numberOfMerchants, setNumberOfMerchants] = useState("");
  const [numberOfBusiness, setNumberOfBusiness] = useState("");
  const balance = token.user.balance;
  useEffect(() => {
    (async () => {
      //number of users
      const users = await axios.get(`${base_url}users`, {
        headers: { Authorization: `Bearer ${token.token}` },
      });
      setNumberOfUsers(users.data.count);
      //number os accounts
      const accounts = await axios.get(`${base_url}accounts`, {
        headers: { Authorization: `Bearer ${token.token}` },
      });
      setNumberOfAccount(accounts.data.count);

      //number of customers
      const customers = await axios.get(`${base_url}customers`, {
        headers: { Authorization: `Bearer ${token.token}` },
      });
      setNumberOfCustomers(customers.data.count);

      //number of agents
      const agents = await axios.get(`${base_url}agents`, {
        headers: { Authorization: `Bearer ${token.token}` },
      });
      setNumberOfAgents(agents.data.count);

      //number of merchants
      const merchants = await axios.get(`${base_url}merchants`, {
        headers: { Authorization: `Bearer ${token.token}` },
      });
      setNumberOfMerchants(merchants.data.count);

      //number of businesses
      const businesses = await axios.get(`${base_url}businesses`, {
        headers: { Authorization: `Bearer ${token.token}` },
      });
      setNumberOfBusiness(businesses.data.count);
    })();
  }, []);

  switch (type) {
    case "users":
      data = {
        title: "USERS",
        isMoney: false,
        value: numberOfUsers,
        link: "See all",
        linkValue: "users",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "accounts":
      data = {
        title: "ACCOUNTS",
        isMoney: false,
        value: numberOfAccount,
        link: "See all",
        linkValue: "accounts",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "customers":
      data = {
        title: "CUSTOMERS",
        isMoney: false,
        value: numberOfCustomers,
        link: "See all",
        linkValue: "customers",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "agents":
      data = {
        title: "AGENTS",
        isMoney: false,
        value: numberOfAgents,
        link: "See all",
        linkValue: "agents",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "merchants":
      data = {
        title: "MERCHANTS",
        isMoney: false,
        value: numberOfMerchants,
        link: "See all",
        linkValue: "merchants",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "businesses":
      data = {
        title: "BUSINESSES",
        isMoney: false,
        value: numberOfBusiness,
        link: "See all",
        linkValue: "businesses",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "balance":
      data = {
        title: "BALANCE",
        isMoney: false,
        value: balance,
        link: "See details",
        linkValue: null,
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;

    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {data.isMoney && "$"} {data.value}
        </span>

        <Link
          to={`/branch/${data.linkValue}`}
          style={{ textDecoration: "none" }}
        >
          <span className="link">{data.link}</span>
        </Link>
      </div>
      <div className="right">
        <div className="percentage positive">
          <KeyboardArrowUpIcon />
          {/* {diff} % */}
        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
