import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import image from "./../../../image/Log.jpg";
import { useState } from "react";
import "./login.scss";
import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { base_url } from "../../../api/Axios";

const theme = createTheme();

export default function Login() {
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isTouched, setIsTouched] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsTouched(true);
    try {
      const response = await axios.post(`${base_url}login`, {
        "app_key":"6eaff6dbb96e8f67c7a6f156a946f95d",
        username: userName,
        password: password,
      });
      const token = response.data;
      console.log(token)
     
      dispatch({ type: "LOGIN", payload: token });
      setErrorMessage(null);
      navigate("/branch");
    } catch (error) {
      const error_message = error.response.data ? error.response.data.detail : error.message;
      setErrorMessage(error_message) 
    
    }
  };

  
  return (
    <div className="logContainer">
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: "50%", width: "70%" }}>
          <CssBaseline />
          <Grid
            item
            xs={true}
            sm={4}
            md={7}
            sx={{
              backgroundImage: `url(${image})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            sx={{ backgroundColor: "#ffcc00" }}
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Sign in to your Account
              </Typography>
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="userName"
                  required
                  placeholder="user name"
                  name="userName"
                  autoComplete="userName"
                  autoFocus
                  sx={{ backgroundColor: "white" }}
                  onChange={(event) => setUserName(event.target.value)}
                  value={userName}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  placeholder="password"
                  type="password"
                  id="password"
                  inputProps={{ minLength: 8 }}
                  autoComplete="current-password"
                  sx={{ backgroundColor: "white" }}
                  onChange={(event) => setPassword(event.target.value)}
                  value={password}
                />
                {isTouched && errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Log In
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}
