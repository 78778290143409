import "../../../style/detail.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import Operation from "./Operation";
const Operations = () => {
  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar title={"Operations"} />
        <div className="top">
         
            <Operation />
       
        </div>
      </div>
    </div>
  );
};

export default Operations;
