import "../../../style/profile.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { AuthContext } from "../../../branch/context/AuthContext";
import { useContext } from "react";
import profileImage from "./../../../image/profile.jpg";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, Navigate } from "react-router-dom";
const theme = createTheme();
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
const MyProfile = () => {
  const { token } = useContext(AuthContext);
  const user = token.user;
  let userGroup = user.group;
  let userLanguage = user.language;
  if(userLanguage === "am"){
    userLanguage = "Amharic"
  } else if(userLanguage === "en"){
    userLanguage = "English"
  } else if(userLanguage === "ti"){
    userLanguage = "Tigrigna"
  }else if(userLanguage === "or"){
    userLanguage = "Oromiffa"
  } else if(userLanguage === "so"){
    userLanguage = "Somali"
  } else if(userLanguage === "af"){
    userLanguage = "Afar"
  }

  if(userGroup === "BRA"){
    userGroup = "Branch"
  } else if(userGroup === "BUS"){
    userGroup = "Business"
  } else if(userGroup === "AGE"){
    userGroup = "Agent"
  }else if(userGroup === "MER"){
    userGroup = "Merchant"
  }
  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar title={"My Profile"} />
        <div className="profileContainer">
          <Link to={""}>   
            <div className="profileListLabel pl">
              <label>Edit</label>
            </div> 
          </Link>
     
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>
                <Stack direction="row" spacing={2}>
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                  >
                    {!user.photo ? (
                      <Avatar
                        alt="User"
                        src={profileImage}
                        style={{ width: "170px", height: "150px" }}
                      />
                    ) : (
                      <Avatar
                        alt="User"
                        src={user.photo}
                        style={{ width: "170px", height: "150px" }}
                      />
                    )}
                  </StyledBadge>
                </Stack>
              </div>
              <div className="userProfileName">
                <Typography component="h1" variant="h6">
                  {`${user.first_name} ${user.last_name}`}
                </Typography>
              </div>

              <Box
                component="form"
                noValidate
                // onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                  <div className="profileListLabel pl">
                      <label>User Name</label>
                    </div>
                    <div className="profileListLabel pl">
                      <label>Phone</label>
                    </div>

                    <div className="profileListLabel pl">
                      <label>Group</label>
                    </div>
                    <div className="profileListLabel pl">
                      <label>Role</label>
                    </div>
                    <div className="profileListLabel pl">
                      <label>Language</label>
                    </div>
                    <div className="profileListLabel pl">
                      <label>Status</label>
                    </div> 
                    <div className="profileListLabel pl">
                      <label>Balance</label>
                    </div>                 
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <div className="profileListLabel">
                      {user.username}
                    </div>
                    <div className="profileListLabel">{user.phone}</div>
                    <div className="profileListLabel">{userGroup}</div>
                    <div className="profileListLabel">{user.role}</div>
                    <div className="profileListLabel">
                      {userLanguage}
                    </div>
                    <div className="profileListLabel">{user.status}</div>  
                    <div className="profileListLabel">{user.balance}</div>                        
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div>
      </div>
    </div>
  );
};

export default MyProfile;
