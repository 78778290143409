import "../../../style/detail.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { useLocation } from "react-router-dom";
import { useState } from "react";
const CustomerDetail = () => {
  const location = useLocation();
  const [zone, setZone] = useState("");
  const customerDetail = location.state.customer;
  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar title={"Customers Detail"} />
        <div className="top">
          <div className="left">
            <div className="editButton">Edit</div>
            <h1 className="title">Customer Detail</h1>
            <div className="item">
              <div className="details">
                <div className="detailItem">
                  <span className="itemKey">First Name:</span>
                  <span className="itemValue">{customerDetail.first_name}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Middle Name:</span>
                  <span className="itemValue">
                    {customerDetail.middle_name}
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Last Name:</span>
                  <span className="itemValue">{customerDetail.last_name}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone Number:</span>
                  <span className="itemValue">{customerDetail.id}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Account Number:</span>
                  <span className="itemValue">{customerDetail.account_no}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Gender:</span>
                  <span className="itemValue">{customerDetail.gender}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Date of Birth:</span>
                  <span className="itemValue">{customerDetail.dob}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">ReferralCode:</span>
                  <span className="itemValue">
                    {customerDetail.referral_code}
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">ID Type:</span>
                  <span className="itemValue">{customerDetail.id_type}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">ID Number:</span>
                  <span className="itemValue">{customerDetail.id_number}</span>
                </div>

                {customerDetail.address && (
                  <div>
                    <div className="detailItem">
                      <span className="itemKey">Zone:</span>
                      <span className="itemValue">
                        {customerDetail.address.zone}
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">City:</span>
                      <span className="itemValue">
                        {customerDetail.address.city}
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Woreda:</span>
                      <span className="itemValue">
                        {customerDetail.address.woreda}
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Kebele:</span>
                      <span className="itemValue">
                        {customerDetail.address.kebele}
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">House Number:</span>
                      <span className="itemValue">
                        {customerDetail.address.house_no}
                      </span>
                    </div>
                  </div>
                )}
                {!customerDetail.address && (
                  <div>
                    <div className="detailItem">
                      <span className="itemKey">Zone:</span>
                      <span className="itemValue"></span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">City:</span>
                      <span className="itemValue"></span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Woreda:</span>
                      <span className="itemValue"></span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Kebele:</span>
                      <span className="itemValue"></span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">House Number:</span>
                      <span className="itemValue"></span>
                    </div>
                  </div>
                )}
                <div className="detailItem">
                  <span className="itemKey">ID Scan:</span>
                  <span className="itemValue">
                    <a href={`${customerDetail.id_scan}`}>
                      {customerDetail.id_scan}
                    </a>
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Photo:</span>
                  <span className="itemValue">
                    <a href={`${customerDetail.photo}`}>
                      {customerDetail.photo}
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="bottom">
          <h1 className="title">Last Transactions</h1>
          <List />
        </div> */}
      </div>
    </div>
  );
};

export default CustomerDetail;
