// import "./../style/datatable.scss";
import "./../style/setting.scss"
import { useState} from "react";
import axios from "axios";
import { AuthContext } from "../branch/context/AuthContext";
import { useContext } from "react";
import { base_url } from "./../api/Axios";
import * as React from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { token, pin_reset_required, password_reset_required } = useContext(AuthContext);
  const [new_password, setNewPassword] = useState("");
  const [new_password_confirmation, setPasswordConfirmation] = useState("");
  const [old_password, setOldPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [newPasswordConfirmError, setNewPasswordConfirmError] = useState("");
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      if (new_password !== new_password_confirmation) {
        setNewPasswordConfirmError(`Those Passwords don't match.`);
        return;
      }
      setNewPasswordConfirmError("");
      const response = await axios.post(
        `${base_url}users/change_password`,
        { old_password, new_password, new_password_confirmation },
        {
          headers: { Authorization: `Bearer ${token.token}` },
        }
      );
      //   console.log(response);
      setNewPassword("");
      setOldPassword("");
      setPasswordConfirmation("");
      toast.success(`You have Changed your password!`);
      navigate("/");
    } catch (error) {
      console.log(error.response.data);
      if (error.response.data.error.detail) {
        setPasswordError(error.response.data.error.detail);
      }
      //   setPasswordError
    }
  };
  const theme = createTheme();
  return (
    <div className="new">
      {!pin_reset_required && !password_reset_required && <Sidebar />}
      <div className="newContainer">
       {!pin_reset_required && !password_reset_required && <Navbar />}
       <div className="formConatiner">
       <div className="top">
          Change Password
        </div>
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box component="form" onSubmit={submitHandler} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Old password "
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  autoFocus
                  inputProps={{ minLength: 8, maxLength: 20 }}
                  value={old_password}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                {passwordError && (
                  <p style={{ color: "red" }}>{passwordError}</p>
                )}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="newPassword"
                  label="New Password"
                  type="password"
                  id="newPassword"
                  autoComplete="new-password"
                  inputProps={{ minLength: 8, maxLength: 20 }}
                  value={new_password}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                {newPasswordError && (
                  <p style={{ color: "red" }}>{newPasswordError}</p>
                )}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="confirmNewPassword"
                  label="confirm New Password"
                  type="password"
                  id="confirmNewPassword"
                  autoComplete="confirm-new-password"
                  inputProps={{ minLength: 8, maxLength: 20 }}
                  value={new_password_confirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
                {newPasswordConfirmError && (
                  <p style={{ color: "red" }}>{newPasswordConfirmError}</p>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Change Password
                </Button>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
       </div>
      </div>
    </div>
  );
};
export default ChangePassword;
