// import "./../style/datatable.scss";
import "./../style/setting.scss"
import { useState, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../branch/context/AuthContext";
import { useContext } from "react";
import { base_url } from "./../api/Axios";
import * as React from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/navbar/Navbar";
import toast from "react-hot-toast";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const ChangeLanguage = () => {
  const { token } = useContext(AuthContext);
  const [language, setLanguage] = useState("en");

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${base_url}users/language`,
        { language },
        {
          headers: { Authorization: `Bearer ${token.token}` },
        }
      );

      toast.success(`You have Changed your Language to ${language}!`);
      //   navigate("/branch/users");
    } catch (error) {
      console.log(error.response.data);
    }
  };
  const theme = createTheme();
  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="formConatiner">
        <div className="top">
            Change Language
          </div>

        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box component="form" onSubmit={submitHandler} sx={{ mt: 1 }}>
                <FormControl fullWidth sx={{ m: 1 }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Change Language
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="Change Language"
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                  >
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="am">አማርኛ</MenuItem>
                    <MenuItem value="or">Oromiffa</MenuItem>
                    <MenuItem value="so">Somali</MenuItem>
                    <MenuItem value="ti">ትግርኛ</MenuItem>
                    <MenuItem value="af">Afar</MenuItem>
                  </Select>
                </FormControl>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Change Language
                </Button>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div>
    </div>
    </div>
  );
};
export default ChangeLanguage;
