import "../../../style/detail.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { useLocation } from "react-router-dom";
import { useState } from "react";
const TransactionDetail = () => {
  const location = useLocation();
  const transactionDetail = location.state.transfer;
  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar title={"Transaction Detail"} />
        <div className="top">
          <div className="left">
            <div className="editButton">Edit</div>
            <h1 className="title">Transaction Detail</h1>
            <div className="item" style={{ "margin-left": "60px" }}>
              <div className="details">
                {/* <h1 className="itemTitle">{transactionDetail.id}</h1> */}
                <div className="detailItem">
                  <span className="itemKey">Transaction ID:</span>
                  <span className="itemValue">{transactionDetail.id}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Payer:</span>
                  <span className="itemValue">{transactionDetail.payer}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Payee:</span>
                  <span className="itemValue">{transactionDetail.payee}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Process:</span>
                  <span className="itemValue">{transactionDetail.process}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Amount:</span>
                  <span className="itemValue">{transactionDetail.amount}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Fee:</span>
                  <span className="itemValue">{transactionDetail.fee}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Description:</span>
                  <span className="itemValue">
                    {transactionDetail.description}
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Detail:</span>
                  <span className="itemValue">{transactionDetail.detail}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Status:</span>
                  <span className="itemValue">{transactionDetail.status}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Status Code:</span>
                  <span className="itemValue">
                    {transactionDetail.status_code}
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Message:</span>
                  <span className="itemValue">{transactionDetail.message}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Created at:</span>
                  <span className="itemValue">
                    {transactionDetail.created_at}
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Updated At:</span>
                  <span className="itemValue">
                    {transactionDetail.updated_at}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionDetail;
