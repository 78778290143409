import "../../../style/new.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import Checkbox from "@mui/material/Checkbox";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { base_url } from "../../../api/Axios";
import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Container from "@mui/material/Container";
import CustomerRegistrationDetail from "./CustomerRegistrationDetail";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import zones from "../zones.json"
const steps = ["Basic Information", "Identification Data", "Address", "Submit"];
function RedBar() {
  return (
    <Box
      sx={{
        height: 20,
        marginLeft: 50,
      }}
    />
  );
}
function RedBar2() {
  return (
    <Box
      sx={{
        height: 15,
        marginLeft: 50,
      }}
    />
  );
}
const NewCustomer = ({ title }) => {
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [phone, setPhoneNumber] = useState("");
  const [first_name, setFirstName] = useState("");
  const [middle_name, setMiddleName] = useState("");
  const [last_name, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDateOfBirth] = useState("");
  const [id_type, setIdType] = useState("");
  const [id_number, setIdNumber] = useState("");
  const [id_scan, setIDScan] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [zone, setZone] = useState("");
  const [city, setCity] = useState("");
  const [woreda, setWoreda] = useState("");
  const [kebele, setKebele] = useState("");
  const [house_no, setHouseNumber] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isTouched, setTouched] = useState(false);
  const [issued_date, setIssuedDate] = useState("");
  const [expiry_date, setExpiryDate] = useState("");
  const [id_back_scan, setIdBackScan] = useState("");
  const [customerDetail, setCustomerDetail] = useState("");
  const [idBackScanName, setIdBackScanName] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const submitHandler = async (e) => {
    e.preventDefault();
    setTouched(true);
    const enteredCustomerInformation = {
      phone: phone,
      first_name: first_name,
      middle_name: middle_name,
      last_name: last_name,
      gender: gender,
      dob: dob,
      id_type: id_type,
      id_number: id_number,
      issued_date: issued_date,
      expiry_date: expiry_date,
      id_back_scan: id_back_scan,
      id_scan: id_scan,
      photo: photo,
      address: {
        zone: zone,
        city: city,
        woreda: woreda,
        kebele: kebele,
        house_no: house_no,
      },
    };
    setCustomerDetail(enteredCustomerInformation);
    console.log("testing", customerDetail);
    if (!phone) {
      setIsPhoneValid(false);
      setPhoneError("Please enter phone number")
      return
    }
    try {
      const response = await axios.post(
        `${base_url}customers`,
        enteredCustomerInformation,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token.token}`,
          },
        }
      );

      toast.success("You have successfully register a new customer!");
      navigate("/branch/customers");
    } catch (error) {
      console.log(error.response.data);
      // if (error.response.data.username[0]) {
      //   setIsUserNameValid(false);
      // }
      if (error.response.data.phone[0]) {
        setIsPhoneValid(false);
        setPhoneError(error.response.data.phone[0])
      }
    }
  };
  const myForm = React.useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step: number) => {
    return;
    // return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    // if (!myForm.current.checkValidity()) {
    //   return;
    // }
    let newSkipped = skipped;
    // if (!myForm.current.checkValidity()) {
    //   return;
    // }
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar title={"Add New Customers"} />
        <div className="top">
          {/* <h1>{title}</h1> */}
        </div>
        {/* <form
          // ref="form"
          ref={myForm}
        > */}
        <Box sx={{ width: "flex%", marginLeft: 10, marginRight: 10 }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode,
              } = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                {activeStep === 0 && (
                  <div className="multipleInput">
                    <Box
                      sx={{
                        position: "relative",
                        display: "grid",
                        // flexDirection: "column",
                        "& .MuiTextField-root": { width: "35ch" },
                      }}
                    >
                      <TextField
                        label={"First Name"}
                        id="margin-dense"
                        // margin="dense"
                        required
                        value={first_name}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <RedBar />

                      <TextField
                        label={"Grand Father Name"}
                        id="margin-normal"
                        // margin="normal"
                        required
                        value={last_name}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      <RedBar />
                      <TextField
                        label={"Phone Number"}
                        id="margin-dense"
                        type="phone"
                        required
                        inputProps={{ maxLength: 13 }}
                        value={phone}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                       {isTouched && !isPhoneValid && (
                        <p style={{ color: "red" }}>
                         {phoneError}
                        </p>
                      )}
                    </Box>
                    <Box
                      sx={{
                        position: "relative",
                        display: "grid",
                        // flexDirection: "column",
                        "& .MuiTextField-root": { width: "35ch" },
                      }}
                    >
                      <RedBar />
                      <TextField
                        label={"Father Name"}
                        id="margin-normal"
                        // margin="dense"
                        required
                        value={middle_name}
                        onChange={(e) => setMiddleName(e.target.value)}
                      />
                      <RedBar2 />
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <InputLabel id="demo-simple-select-helper-label">
                          Select Gender
                        </InputLabel>
                        <Select
                          id="margin-normal"
                          labelId="demo-simple-select-helper-label"
                          label="Select Gender"
                          required
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <MenuItem value="">None</MenuItem>
                          <MenuItem value="MALE">MALE</MenuItem>
                          <MenuItem value="FEMALE">FEMALE</MenuItem>
                        </Select>
                      </FormControl>
                      <RedBar2 />
                      <InputLabel id="demo-simple-select-helper-label">
                        Date of Birth
                      </InputLabel>
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          // label={'margin="normal"'}
                          id="margin-normal"
                          type="date"
                          required
                          value={dob}
                          onChange={(e) => setDateOfBirth(e.target.value)}
                        />
                      </FormControl>
                      <RedBar />
                    </Box>
                  </div>
                )}

                {activeStep === 1 && (
                  <div className="multipleInput">
                    <Box
                      sx={{
                        // marginLeft: "25%",
                        position: "relative",
                        display: "grid",
                        // flexDirection: "column",
                        "& .MuiTextField-root": { width: "35ch" },
                      }}
                    >
                      <RedBar />
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <InputLabel id="demo-simple-select-helper-label">
                          ID Type
                        </InputLabel>
                        <Select
                          id="margin-normal"
                          labelId="demo-simple-select-helper-label"
                          label="ID Type"
                          value={id_type}
                          onChange={(e) => setIdType(e.target.value)}
                        >
                          <MenuItem>Select ID Type</MenuItem>
                          <MenuItem value="NA">National ID</MenuItem>
                          <MenuItem value="DR">Driver License</MenuItem>
                          <MenuItem value="PA">Passport</MenuItem>
                          <MenuItem value="KE">Kebele ID</MenuItem>
                          <MenuItem value="ST">Student ID</MenuItem>
                          <MenuItem value="EM">Employee ID</MenuItem>
                          <MenuItem value="OT">Other</MenuItem>
                        </Select>
                      </FormControl>

                      <RedBar />

                      <InputLabel id="demo-simple-select-helper-label">
                        ID Issued Date
                      </InputLabel>
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          // label={'margin="normal"'}
                          id="margin-normal"
                          type="date"
                          required
                          value={issued_date}
                          onChange={(e) => setIssuedDate(e.target.value)}
                        />
                      </FormControl>

                      <RedBar />
                      <InputLabel id="demo-simple-select-helper-label">
                        ID Scan
                      </InputLabel>
                      <TextField
                        id="margin-normal"
                        // margin="normal"
                        type="file"
                        // name="myImage"
                        // value={idScanName}
                        onChange={(e) => {
                          console.log(e.target.files);
                          setIDScan(e.target.files[0]);
                        }}
                      />

                      <RedBar />
                    </Box>
                    <Box
                      sx={{
                        // marginLeft: "25%",
                        position: "relative",
                        display: "grid",
                        // flexDirection: "column",
                        "& .MuiTextField-root": { width: "35ch" },
                      }}
                    >
                      {/* <RedBar /> */}
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          label={"ID Number"}
                          id="margin-normal"
                          type="text"
                          required
                          value={id_number}
                          onChange={(e) => setIdNumber(e.target.value)}
                        />
                      </FormControl>
                      <RedBar />
                      <InputLabel id="demo-simple-select-helper-label">
                        ID Expiry Date
                      </InputLabel>
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          // label={'margin="normal"'}
                          id="margin-normal"
                          type="date"
                          required
                          value={expiry_date}
                          onChange={(e) => setExpiryDate(e.target.value)}
                        />
                      </FormControl>
                      <RedBar />

                      <InputLabel id="demo-simple-select-helper-label">
                        ID Back Scan
                      </InputLabel>
                      <TextField
                        id="margin-normal"
                        // margin="normal"
                        type="file"
                        value={idBackScanName}
                        onChange={(e) => {
                          setIdBackScan(e.target.files[0]);
                          setIdBackScanName(e.target.files[0].name);
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        // marginLeft: "25%",
                        position: "relative",
                        display: "grid",
                        // flexDirection: "column",
                        "& .MuiTextField-root": { width: "35ch" },
                      }}
                    ></Box>
                  </div>
                )}
                {activeStep === 2 && (
                  <div className="multipleInput">
                    <Box
                      sx={{
                        // marginLeft: "25%",
                        position: "relative",
                        display: "grid",
                        // flexDirection: "column",
                        "& .MuiTextField-root": { width: "35ch" },
                      }}
                    >
                      <RedBar />
                      <InputLabel id="demo-simple-select-helper-label">
                        Photo
                      </InputLabel>
                      <TextField
                        id="margin-normal"
                        // margin="normal"
                        type="file"
                        onChange={(e) => {
                          setPhoto(e.target.files[0]);
                          console.log(e.target.files[0].name);
                        }}
                        // value={photoName}
                      />
                      <RedBar />
                      <TextField
                        label={"City/Town"}
                        id="margin-normal"
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />

                      <RedBar />
                      <TextField
                        label={"Kebele"}
                        id="margin-normal"
                        type="text"
                        value={kebele}
                        onChange={(e) => setKebele(e.target.value)}
                      />

                      <RedBar />
                    </Box>
                    <Box
                      sx={{
                        // marginLeft: "25%",
                        position: "relative",
                        display: "grid",
                        // flexDirection: "column",
                        "& .MuiTextField-root": { width: "35ch" },
                      }}
                    >
                      <RedBar />
                      <RedBar />
                      {/* <TextField
                        label={"Zone/Sub City"}
                        id="margin-normal"
                        type="text"
                        value={zone}
                        onChange={(e) => setZone(e.target.value)}
                      /> */}
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <InputLabel id="demo-simple-select-helper-label">
                        Zone/Sub City
                        </InputLabel>
                        <Select
                          id="margin-normal"
                          labelId="demo-simple-select-helper-label"
                          label={"Zone/Sub City"}
                          value={zone}
                          onChange={(e) => setZone(e.target.value)}
                        >
                          <MenuItem>Select Zone/Sub City</MenuItem>
                          {zones.map((zone)=>{
                            return <MenuItem value={zone.id}>{zone.name}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                      <RedBar />
                      <TextField
                        label={"Woreda"}
                        id="margin-normal"
                        type="text"
                        value={woreda}
                        onChange={(e) => setWoreda(e.target.value)}
                      />

                      <RedBar />

                      <TextField
                        label={"House Number"}
                        id="margin-normal"
                        type="text"
                        value={house_no}
                        onChange={(e) => setHouseNumber(e.target.value)}
                      />
                      <RedBar />
                    </Box>
                  </div>
                )}
                {activeStep === 3 && (
                  <>
                    <CustomerRegistrationDetail
                      phone={phone}
                      first_name={first_name}
                      middle_name={middle_name}
                      last_name={last_name}
                      gender={gender}
                      dob={dob}
                      id_type={id_type}
                      id_number={id_number}
                      issued_date={issued_date}
                      expiry_date={expiry_date}
                      id_back_scan={id_back_scan}
                      id_scan={id_scan}
                      photo={photo}
                      zone={zone}
                      city={city}
                      woreda={woreda}
                      kebele={kebele}
                      house_no={house_no}
                      isTouched={isTouched}
                      phoneError={phoneError}
                    />
                  </>
                )}
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  // color={"#000"}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                  
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}
                {activeStep !== steps.length - 1 && (
                  <Button
                    type="submit"
                    onClick={handleNext}
                    sx={{ bgcolor: "#0066cc", color: "#000" }}
                  >
                    Next
                  </Button>
                )}
                {activeStep === steps.length - 1 && (
                  <>
                    <Button
                      onClick={submitHandler}
                      sx={{
                        bgcolor: "#0066cc",
                        color: "#000",
                        marginLeft: 100,
                      }}
                    >
                      Register
                    </Button>
                  </>
                )}
              </Box>
            </React.Fragment>
            // </form>
          )}
        </Box>
        {/* </form> */}
      </div>
    </div>
  );
};

export default NewCustomer;
