import "../../../style/businessDetail.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { useLocation } from "react-router-dom";
const BusinessDetail = () => {
  const location = useLocation();
  const businessDetailData = location.state.business;
  const  group  = location.state.group;
  const formContainer = (labelData, spanData)=>{
    return   <div className="detailItem">
    <span className="profileListLabel pl">{labelData}:</span>
    <span className="profileListLabel p2">{spanData}</span>
  </div>
  }
  const merchantCodeName = businessDetailData.merchant_id? "Merchant code": "Agent Code";
  const title = businessDetailData.merchant_id? "Merchant Detail": "Agent Detail";
  const merchantCodeValue = businessDetailData.merchant_id?businessDetailData.merchant_id: businessDetailData.code;
  const zone = businessDetailData.address? businessDetailData.address.zone: null;
  const city = businessDetailData.address? businessDetailData.address.city: null;
  const woreda = businessDetailData.address? businessDetailData.address.woreda: null;
  const kebele = businessDetailData.address? businessDetailData.address.kebele: null;
  const house_no = businessDetailData.address? businessDetailData.address.house_no: null;
  const specific_location = businessDetailData.address? businessDetailData.address.specific_location: null;
  const is_business = businessDetailData.address? businessDetailData.address.is_business: null;
  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar title={title} />
        <div className="detailContainer">
          <div className="column"> 
                {formContainer(merchantCodeName, merchantCodeValue)}
                {formContainer("Phone Number", businessDetailData.phone)}
                {formContainer("Short Code", businessDetailData.short_code)}
                {formContainer("Business Name", businessDetailData.business_name)}
                {formContainer("Short Name", businessDetailData.short_name)}
                { group !== "merchant" && (formContainer("Type", businessDetailData.type))}
                {formContainer("Photo", <a href={`${businessDetailData.photo}`}>{businessDetailData.photo && "Click here"}</a>)}
                {formContainer("ID Type", businessDetailData.id_type)}
                {formContainer("ID Number", businessDetailData.id_number)}
                {formContainer("Issued Date", businessDetailData.issued_date)}
                {formContainer("Expiry Date", businessDetailData.expiry_date)}
                {formContainer("ID Scan", <a href={`${businessDetailData.id_scan}`}>{businessDetailData.id_scan && "Click here"}</a>)}
                {formContainer("ID Scan back", <a href={`${businessDetailData.id_back_scan}`}>{businessDetailData.id_back_scan && "Click here"}</a>)}
                {formContainer("Status", businessDetailData.status)}
          </div>
          <div className="column"> 
                {formContainer("Owner Name", businessDetailData.owner_name)}
                {formContainer("Owner phone", businessDetailData.owner_phone)}
                {formContainer("Owner Zone", businessDetailData.owner_Zone)}
                {formContainer("Owner City", businessDetailData.owner_city)}
                {formContainer("Owner Woreda", businessDetailData.owner_woreda)}
                {formContainer("Owner Kebele", businessDetailData.owner_kebele)}
                {formContainer("Owner house Number", businessDetailData.owner_house_no)}
                {formContainer("Nationality", businessDetailData.nationality)}
                {formContainer("Memorandum of Agreement", <a href={`${businessDetailData.moa}`}>{businessDetailData.moa && "Click here"}</a>)}
                {formContainer("Article of Association", <a href={`${businessDetailData.aoa}`}>{businessDetailData.aoa && "Click here"}</a>)}
                {formContainer("Business License", <a href={`${businessDetailData.license}`}>{businessDetailData.license && "Click here"}</a>)}
                {formContainer("TIN Certificate", <a href={`${businessDetailData.tin}`}>{businessDetailData.tin && "Click here"}</a>)}
                {formContainer("VAT Registration Certificate", <a href={`${businessDetailData.vat}`}>{businessDetailData.vat && "Click here"}</a>)}
            </div>
             <div className="column"> 
                {formContainer("Contract Agreement", <a href={`${businessDetailData.contract}`}>{businessDetailData.contract && "Click here"}</a>)}
                {formContainer("Trade Registration", <a href={`${businessDetailData.registration}`}>{businessDetailData.registration && "Click here"}</a>)}
                {formContainer("Other", <a href={`${businessDetailData.other}`}>{businessDetailData.other && "Click here"}</a>)}
                {formContainer("Number of Employees", businessDetailData.no_employee)}
                {formContainer("Level", businessDetailData.level)}
                {formContainer("TIN", businessDetailData.tin_value)}
                {formContainer("Zone",zone)}
                {formContainer("City",city)}
                {formContainer("Woreda",woreda)}
                {formContainer("Kebele",kebele)}
                {formContainer("House Number",house_no)}
                {formContainer("Specific Location",specific_location)}
                {formContainer("Is Business",is_business)}
             </div>
      </div>
      </div>
    </div>
  );
};

export default BusinessDetail;
