// import "./../style/datatable.scss";
import "./../style/setting.scss"
import { useState } from "react";
import axios from "axios";
import { AuthContext } from "../branch/context/AuthContext";
import { useContext } from "react";
import { base_url } from "./../api/Axios";
import * as React from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const Activate = () => {
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [new_pin, setNewPin] = useState("");
  const [new_pin_confirmation, setPinConfirmation] = useState("");
  const [pin, setOldPin] = useState("");
  const [pinError, setPinError] = useState("");
  const [newPinError, setNewPinError] = useState("");
  const [newPinConfirmError, setNewPinConfirmError] = useState("");
  const [permissionError, setPermissionError] = useState("");
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      if (pin.length !== 4) {
        setPinError(` Pin length must be 4!`);
        return;
      }
      setPinError("");
      if (new_pin.length !== 4) {
        setNewPinError(` Pin length must be 4!`);
        return;
      }
      setNewPinError("");
      if (new_pin_confirmation.length !== 4) {
        setNewPinConfirmError(` Pin length must be 4!`);
        return;
      }
      if (new_pin !== new_pin_confirmation) {
        setNewPinConfirmError(`Those pins don't match.`);
        return;
      }
      setNewPinConfirmError("");
      console.log(pin, new_pin, new_pin_confirmation);

      const response = await axios.post(
        `${base_url}users/activate`,
        { pin, new_pin, new_pin_confirmation },
        {
          headers: { Authorization: `Bearer ${token.token}` },
        }
      );
      //   console.log(response);
      setNewPin("");
      setOldPin("");
      setPinConfirmation("");
      toast.success(`You have Activated!`);
      navigate("/");
    } catch (error) {
      // console.log(error.response.data.detail);
      const errorType = error.response.data.new_pin_confirmation
        ? "Invalid PIN"
        : "You do not have permission to perform this action";
      setPermissionError(errorType);
    }
  };
  const theme = createTheme();
  return (
    <div className="new">
      {/* <Sidebar /> */}
      <div className="newContainer">
        {/* <Navbar /> */}
        <div className="formConatiner"> 
        <div className="top">
          Activate
        </div>

        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >

              <Box component="form" onSubmit={submitHandler} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Pin "
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  autoFocus
                  inputProps={{ maxLength: 4, minLength: 4 }}
                  value={pin}
                  onChange={(e) => setOldPin(e.target.value)}
                />
                {pinError && <p style={{ color: "red" }}>{pinError}</p>}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="newPin"
                  label="New pin"
                  type="password"
                  id="newPin"
                  autoComplete="new-password"
                  inputProps={{ maxLength: 4, minLength: 4 }}
                  value={new_pin}
                  onChange={(e) => setNewPin(e.target.value)}
                />
                {newPinError && <p style={{ color: "red" }}>{newPinError}</p>}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="confirmNewPin"
                  label="confirm New pin"
                  type="password"
                  id="confirmNewPin"
                  autoComplete="confirm-new-password"
                  inputProps={{ maxLength: 4, minLength: 4 }}
                  value={new_pin_confirmation}
                  onChange={(e) => setPinConfirmation(e.target.value)}
                />
                {newPinConfirmError && (
                  <p style={{ color: "red" }}>{newPinConfirmError}</p>
                )}
                {permissionError && (
                  <p style={{ color: "red" }}>{permissionError}</p>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Activate
                </Button>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div>
    </div>
    </div>
  );
};
export default Activate;
