import "../../style/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../../branch/context/AuthContext";
import { useContext } from "react";
import { base_url } from "../../api/Axios";
import * as React from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";

const SpecificReconciliation = () => {
  const [reconciliationData, setReconciliationData] = useState([]);
  const { token } = useContext(AuthContext);
  const location = useLocation();
  const [pageSize, setPageSize] = useState(10);
  const url = location.state.url;
  const action = location.state.action;
  const category = location.state.category;
  // console.log(`${base_url}${category}/${url}/reconciliation?page_size=200`);
  //   console.log(`${base_url}${url}/${action}`);
  //   console.log(`${base_url}${category}/${url}/${action}`);

  useEffect(() => {
    (async () => {
      const response = await axios.get(
        `${base_url}${category}/${url}/reconciliation?page_size=200`,
        {
          headers: { Authorization: `Bearer ${token.token}` },
        }
      );

      setReconciliationData(response.data.results);
    })();
  }, []);
  let i = 0;
  const listOfReconciliations = reconciliationData.map((reconciliation) => {
    return {
      id: Number(++i),
      initiator: reconciliation.initiator,
      credits: reconciliation.credits,
      debits: reconciliation.debits,
    };
  });

  const reconciliationColumn = [
    // { field: "id", headerName: "ID", width: 70 },

    {
      field: "initiator",
      headerName: "Initiator",
      width: 100,
    },
    {
      field: "credits",
      headerName: "Credits",
      width: 150,
    },

    {
      field: "debits",
      headerName: "Debits",
      width: 100,
    },
  ];

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <div className="datatable">
          <div className="datatableTitle">Reconciliation</div>
          <DataGrid
            className="datagrid"
            rows={listOfReconciliations}
            columns={reconciliationColumn}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20, 50]}
            checkboxSelection
            sx={{
              boxShadow: 2,
              // border: 2,
              // borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SpecificReconciliation;
