import { useState, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { base_url } from "../../../api/Axios";
import * as React from "react";
import toast from "react-hot-toast";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Phone from "@mui/icons-material/Phone";
import Container from "@mui/material/Container";
import CodeIcon from "@mui/icons-material/Code";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};
const BillPay = () => {
  const [to, setTo] = useState("");
  const [payer, setPayer] = useState("");
  const [pin, setPin] = useState("");

  const [isTouched, setIsTouched] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [pinError, setPinError] = useState("");
  const { token } = useContext(AuthContext);
  const [isValid, setIsValid] = useState(false);
  const [open, setOpen] = useState(false);
  const [billerCodeError, setBillerCodeError] = useState(false)
  const [transactionDetail, setTransactionDetail] = useState("");
  const toChangeHandler = (e) => {
    setTo(e.target.value);
  };
  const payerChangeHandler = (e) => {
    setPayer(e.target.value);
  };
  const pinChangeHandler = (e) => {
    setPin(e.target.value);
  };
  const bodyData = {
    to: to,
    payer: payer,
    // pin: pin,
  };
  const bodyData2 = {
    to: to,
    payer: payer,
    pin: pin,
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    setIsTouched(true);
    try {
      const response = await axios.post(
        `${base_url}transfers/bill_pay/validate`,
        bodyData,
        {
          headers: { Authorization: `Bearer ${token.token}` },
        }
      );
      // console.log(response.data);
      setTransactionDetail(response.data);
      setIsValid(true);
      setBillerCodeError(false)
      setOpen(true);
    } catch (error) {
      console.log(error.response.data);
      if(error.response.data.to){
        setBillerCodeError(error.response.data.to[0])
      }
      else if(error.response.data.error){
        setBillerCodeError(false)
        setErrorMessage(error.response.data.error.detail);
      }
    
    }
  };
  const ConfirmationHandler = async (e) => {
    e.preventDefault();
    setIsTouched(true);
    try {
      const response = await axios.post(
        `${base_url}transfers/bill_pay`,
        bodyData2,
        {
          headers: { Authorization: `Bearer ${token.token}` },
        }
      );
      console.log(response.data);
      setTo("");
      setPayer("");
      setPin("");
      setErrorMessage(false);
      toast(response.data.detail);
      setPinError(false);
      setOpen(false);
    } catch (error) {
      console.log(error.response.data);
      setPinError(error.response.data.error.detail);
      setErrorMessage(error.response.data.to[0]);
    }
  };
  const handleClose = () => setOpen(false);
  const theme = createTheme();
  return (
    <div className="new">
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box component="form" onSubmit={submitHandler} sx={{ mt: 1 }}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="to">Biller Code</InputLabel>
                <OutlinedInput
                  id="text"
                  required
                  type="text"
                  value={to}
                  onChange={toChangeHandler}
                  startAdornment={
                    <InputAdornment position="start">
                      <CodeIcon />
                    </InputAdornment>
                  }
                  label="Biller Code"
                />
              </FormControl>
              {isTouched && billerCodeError && (
                <p style={{ color: "red" }}>{billerCodeError}</p>
              )}
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="payer">Customer ID</InputLabel>
                <OutlinedInput
                  id="payer"
                  required
                  type="text"
                  value={payer}
                  onChange={payerChangeHandler}
                  startAdornment={
                    <InputAdornment position="start">
                      <PersonOutlineIcon />
                    </InputAdornment>
                  }
                  label="Customer ID"
                />
              </FormControl>

              {isTouched && errorMessage && (
                <p style={{ color: "red" }}>{errorMessage}</p>
              )}
              <FormControl fullWidth sx={{ m: 1 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Bill Pay
                </Button>
              </FormControl>
            </Box>
          </Box>
        </Container>
        {isValid && (
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <div className="loginConterFirst">
                  <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="xs">
                      <CssBaseline />
                      <Box
                        sx={{
                          marginTop: 2,
                          display: "flex",
                          width: "250px",
                          marginBottom: 6,

                          flexDirection: "column",
                          alignItems: "center",
                          fontSize: 15,
                        }}
                      >
                        <Box noValidate sx={{ mt: 1 }}>
                          <form onSubmit={ConfirmationHandler}>
                            <h3
                              className="itemTitle"
                              style={{ textDecoration: "underline" }}
                            >
                              Transaction Detail
                            </h3>
                            <div className="detailItem">
                              <span className="itemKey">Name: </span>
                              <span className="itemValue">
                                {transactionDetail.to_name}
                              </span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey">Amount: </span>
                              <span className="itemValue">
                                {transactionDetail.amount}
                              </span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey">Fee: </span>
                              <span className="itemValue">
                                {transactionDetail.fee}
                              </span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey">To: </span>
                              <span className="itemValue">
                                {transactionDetail.to}
                              </span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey">Total: </span>
                              <span className="itemValue">
                                {transactionDetail.amount +
                                  transactionDetail.fee}
                              </span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey">Status: </span>
                              <span className="itemValue">
                                {transactionDetail.status}
                              </span>
                            </div>
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="pin"
                              label="Enter pin "
                              name="pin"
                              type="password"
                              autoComplete="current-pin"
                              autoFocus
                              inputProps={{ maxLength: 4, minLength: 4 }}
                              value={pin}
                              onChange={(e) => setPin(e.target.value)}
                            />
                            {isTouched && pinError && (
                              <p style={{ color: "red" }}>{pinError}</p>
                            )}
                            <Button
                              className="loginButton"
                              type="submit"
                              fullWidth
                              variant="contained"
                              sx={{ mt: 3, mb: 1 }}
                            >
                              Confirm
                            </Button>
                          </form>
                        </Box>
                      </Box>
                    </Container>
                  </ThemeProvider>
                </div>
              </Typography>
            </Box>
          </Modal>
        )}
      </ThemeProvider>
    </div>
  );
};
export default BillPay;
