import "../../../style/list.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import BusinessDataTable from "./BusinessDataTable";
const BusinessList = () => {
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar title={"Business Accounts"} />
        <BusinessDataTable />
      </div>
    </div>
  );
};

export default BusinessList;
