import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import "./home.scss";
import Widget from "../../../components/widget/Widget";
import Featured from "../../../components/featured/Featured";
import Chart from "../../../components/chart/Chart";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
const Home = () => {
  const { group } = useContext(AuthContext);
  console.log(group);
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          <Widget type="users" />
          <Widget type="accounts" />
          {group === "AGE" && <Widget type="customers" />}
          {group === "BRA" && <Widget type="customers" />}
          {group === "BRA" && <Widget type="agents" />}
          {group === "BRA" && <Widget type="merchants" />}
          {group === "BRA" && <Widget type="businesses" />}
          <Widget type="balance" />
        </div>
        <div className="charts">
          <Featured />
          <Chart title="Last 6 Months number users added" aspect={2 / 1} />
        </div>
        {/* <div className="listContainer">
          <div className="listTitle">Latest Transactions</div>
          <Table />
        </div> */}
      </div>
    </div>
  );
};

export default Home;
