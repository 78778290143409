import "./featured.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import * as React from 'react';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Button } from "@mui/material";
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../branch/context/AuthContext";
import { useContext } from "react";
import { base_url } from "../../api/Axios";
import axios from 'axios'
const Featured = () => {
  const [search, setSearch]= useState("");
  const [searchedUser, setSearchedUSer] = useState("");
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [userError, setUserError] = useState(false);
  const searchHandler = async () => {
    const response = await axios.get(
      `${base_url}accounts?search=${search}`,
      {
        headers: { Authorization: `Bearer ${token.token}` },
      }
    );
     setSearchedUSer(response.data.results[0]);
     if(search && response.data.results[0]){
       navigate("/searchedResult", {
       state: { searchedUser: response.data.results },
      });
      }
    else{
     setUserError(true);
     }
  };
  // console.log(`${base_url}accounts?search=${search}`)
  // console.log(searchedUser)
  return (
    <div className="featured">
      {/* <div className="top">
        <h1 className="title">Total Number of Users</h1>
        <MoreVertIcon fontSize="small" />
      </div>
      <div className="bottom">
        <div className="featuredChart">
          <CircularProgressbar value={10} text={"10%"} strokeWidth={5} />
        </div>
        <p className="title">Total user logged to our system today</p>

        <div className="summary">
          <div className="item">
            <div className="itemTitle">Today</div>
            <div className="itemResult negative">
              <KeyboardArrowDownIcon fontSize="small" />
              <div className="resultAmount">100</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Last Week</div>
            <div className="itemResult positive">
              <KeyboardArrowUpOutlinedIcon fontSize="small" />
              <div className="resultAmount">1000</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Last Month</div>
            <div className="itemResult positive">
              <KeyboardArrowUpOutlinedIcon fontSize="small" />
              <div className="resultAmount">12.4k</div>
            </div>
          </div>
        </div>
      </div> */}
       <Box sx={{ '& > :not(style)': { m: 1 } }}>
       <FormControl variant="standard">
        {/* <InputLabel htmlFor="input-with-icon-adornment">
          Search user
        </InputLabel> */}
        <Input
          id="input-with-icon-adornment"
          placeholder=" Search user"
          required
          onChange={(e) => setSearch(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          }
        />
       
      </FormControl>
      <FormControl variant="standard">
              <Button
                type="submit"
                // onClick={filterHandler}
                variant="contained"
                // sx={{ mt: 1, mb: 1 }}
                onClick={searchHandler}
              >
                Search
              </Button>
      </FormControl>
    </Box>
    {userError && <p style={{color:"red"}}>User not Found!</p>}
    </div>
  );
};

export default Featured;
