import "../../style/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../../branch/context/AuthContext";
import { useContext } from "react";
import { base_url } from "../../api/Axios";
import * as React from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
const Operators = () => {
  const [activityLogsData, setActivityLogsData] = useState([]);
  const { token } = useContext(AuthContext);
  const location = useLocation();
  const url = location.state.url;
  const action = location.state.action;
  const category = location.state.category;
  console.log("from user", url, action, category);
  //   console.log(`${base_url}${url}/${action}`);
  //   console.log(`${base_url}${category}/${url}/${action}`);
  useEffect(() => {
    (async () => {
      const activityLogs = await axios.get(
        `${base_url}${category}/${url}/${action}`,
        {
          headers: { Authorization: `Bearer ${token.token}` },
        }
      );

      setActivityLogsData(activityLogs.data.results);
      console.log(activityLogs.data.results);
    })();
  }, []);
  let i = 0;
  const listOfLogs = activityLogsData.map((user) => {
    return {
      id: Number(++i),
      username: user.username,
      first_name: user.first_name,
      last_name: user.last_name,
      id_number: user.id_number,
      group: user.group,
      id_type: user.id_type,
      language: user.language,
      role: user.role,
      phone: user.phone,
      balance: user.balance,
      status: user.status,
    };
  });

  const activityLogsColumn = [
    // { field: "id", headerName: "ID", width: 70 },
    {
      field: "username",
      headerName: "User Name",
      width: 130,
    },
    {
      field: "first_name",
      headerName: "First Name",
      width: 120,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 90,
    },
    {
      field: "group",
      headerName: "Group",
      width: 130,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 140,
    },
    {
      field: "role",
      headerName: "Role",
      width: 120,
    },
    {
      field: "id_number",
      headerName: "ID Number",
      width: 120,
    },
    {
      field: "id_type",
      headerName: "ID Type",
      width: 120,
    },
    {
      field: "language",
      headerName: "language",
      width: 120,
    },

    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`}>
            {params.row.status}
          </div>
        );
      },
    },
  ];

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar title="Operators"/>
        <div className="datatable">
          {/* <div className="datatableTitle">Operators</div> */}
          <DataGrid
            className="datagrid"
            rows={listOfLogs}
            columns={activityLogsColumn}
            pageSize={9}
            rowsPerPageOptions={[9]}
            checkboxSelection
            sx={{
              boxShadow: 2,
              // border: 2,
              // borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Operators;
