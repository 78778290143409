import "../../../style/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { base_url } from "../../../api/Axios";

const AgentsDataTable = () => {
  const [agentsData, setAgentsData] = useState([]);
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [currentURL, setCurrentURL] = useState("");
  const [action, setAction] = useState("");
  const [pageSize, setPageSize] = useState(10);
  
  useEffect(() => {
    (async () => {
      const response = await axios.get(`${base_url}agents?page_size=all`, {
        headers: { Authorization: `Bearer ${token.token}` },
      });
      setAgentsData(response.data.results);
    })();
  });
  let i = 0;
  const listOfAgents = agentsData.map((agent) => {
    return {
      id: Number(++i),
      code: agent.code,
      business_name: agent.business_name,
      short_code: agent.short_code,
      type: agent.type,
      short_name: agent.short_name,
      phone: agent.phone,
      photo: agent.photo,
      id_type: agent.id_type,
      id_number: agent.id_number,
      issued_date: agent.issued_date,
      expiry_date: agent.expiry_date,
      id_scan: agent.id_scan,
      id_back_scan: agent.id_back_scan,
      owner_name: agent.owner_name,
      owner_phone: agent.owner_phone,
      owner_zone: agent.owner_zone,
      owner_city: agent.owner_city,
      owner_woreda: agent.owner_woreda,
      owner_kebele: agent.owner_kebele,
      owner_house_no: agent.owner_house_no,
      moa: agent.moa,
      aoa: agent.aoa,
      license: agent.license,
      tin: agent.tin,
      vat: agent.vat,
      contract: agent.contract,
      registration: agent.registration,
      other: agent.other,
      tin_value: agent.tin_value,
      no_employee: agent.no_employee,
      level: agent.level,
      status: agent.status,
      address: agent.address,
      is_business: agent.is_business,
      url: agent.url,
    };
  });
  // console.log(listOfAgents);
  const userColumns = [
    // { field: "id", headerName: "ID", width: 70 },
    {
      field: "code",
      headerName: "Agent ID",
      width: 100,
    },
    {
      field: "phone",
      headerName: "Service Number",
      width: 150,
    },
    {
      field: "type",
      headerName: "Type of Business",
      width: 120,
    },
    {
      field: "business_name",
      headerName: "Business Name",
      width: 120,
    },
    {
      field: "short_name",
      headerName: "Short Name",
      width: 100,
    },
    {
      field: "owner_name",
      headerName: "Owner Name",
      width: 100,
    },
    {
      field: "owner_phone",
      headerName: "Owner Phone",
      width: 150,
    },
  ];
  const detailHandler = (agents) => {
    console.log(agents);
    navigate("/branch/businesses/businessDetail", {
      state: { business: agents },
    });
  };

  const actionHandler = (e) => {
    e.preventDefault();
    if (currentURL)
      navigate(`/${action}`, {
        state: {
          url: currentURL.split("/")[5],
          action: action,
          category: "agents",
        },
      });
  };
  const actionColumn = [
    {
      field: "Detail",
      headerName: "Detail",
      width: 70,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="viewButton"
              onClick={() => detailHandler(params.row)}
            >
              <VisibilityIcon />
            </div>
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 160,
      renderCell: (params) => {
        return (
          <div className="formInput">
            <select
              className="formInput"
              name="active"
              id="active"
              // value={currentURL}
              onChange={(e) => {
                setAction(e.target.value);
                setCurrentURL(params.row.url);
              }}
              onClick={actionHandler}
              style={{
                backgroundColor: "#E1E1E1",
                color: "#000",
                fontSize: 15,
              }}
            >
              <option value="">Extra Action</option>
              <option value="activity_log">Activity Log</option>
              <option value="change_status">Change Status</option>
              <option value="specificStatement">Statement</option>
              <option value="specificSummary">Summary</option>
              <option value="transfers">transactions</option>
              <option value="operators">operators</option>
              <option value="specificCommissions">commissions</option>
            </select>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      {/* <div className="datatableTitle">
        Agents List
        <Link to="/branch/agents/newAgent" className="link">
          <AddIcon />
        </Link>
      </div> */}
      <DataGrid
        rowHeight={40}
        className="datagrid"
        rows={listOfAgents}
        columns={userColumns.concat(actionColumn)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        checkboxSelection
        sx={{
          boxShadow: 2,
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
      />
    </div>
  );
};

export default AgentsDataTable;
