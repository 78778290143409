import SidebarItem from "./SidebarItem"
// import items from "./data/sidebar.json"
// import { Link, Navigate } from "react-router-dom";
// import { DarkModeContext } from "../../branch/context/darkModeContext";
import { useContext } from "react";
import { AuthContext } from "../../branch/context/AuthContext";
import './sidebarNew.css'
import tellerItems from "./data/tellerItems.json"
import cashierItems from "./data/cashierItems.json"
import branchManagerItems from './data/branchManagerItems.json'
import masterAgentItems from "./data/MasterAgentItems.json"
import merchantItems from "./data/merchantItems"
import businessItems from "./data/businessItems.json"
import agentItem from "./data/agentItems.json"
// import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
export default function Sidebar() {
    // const { dispatch } = useContext(AuthContext);
    const { group } = useContext(AuthContext);
    const { role } = useContext(AuthContext)
    const { profile } = useContext(AuthContext)
    // console.log("side bar", role, group, profile);
    return ( <div className = "sidebar" >
                <div className = "top" >
                    <Toolbar >
                        <Typography variant = "h6" noWrap component = "div" > 
                            {group === "BRA" && role === "MANAGER" && <span className = "logo"> Manager Portal </span>} 
                            { group === "BRA" && profile === "Branch/Outlet" && <span className = "logo"> Teller Portal </span>} 
                            {group === "BRA" && role === "CASHIER" && <span className = "logo"> Cashier Portal </span>} 
                            {group === "BUS" && <span className = "logo"> Business Portal </span>} 
                            {group === "AGE" && profile === "Master Agent" && <span className = "logo"> Master Agent</span>}
                            {group === "AGE" && profile !== "Master Agent" && <span className = "logo"> Agent Portal </span>} 
                            {group === "MER" && <span className = "logo"> Merchant Portal </span>} 
                        </Typography>
                    </Toolbar> 
                </div> 
                < hr /> 
                { group === "BRA" && profile === "Branch/Outlet" && tellerItems.map((item, index) => 
                            <SidebarItem key = { index }  item = { item } />) } 
                { group === "BRA" && role === "CASHIER" && cashierItems.map((item, index) => 
                            <SidebarItem key = { index } item = { item }/>) } 
                {group === "BRA" && role === "MANAGER" && branchManagerItems.map((item, index) => 
                            <SidebarItem key = { index }item = { item }/>) } 
                { group === "AGE" && profile === "Master Agent" && masterAgentItems.map((item, index) => 
                            <SidebarItem key = { index } item = { item }/>) } 
                { group === "AGE" && profile !== "Master Agent" && agentItem.map((item, index) => 
                            <SidebarItem key = { index } item = { item }/>) } 
                { group === "MER" && merchantItems.map((item, index) => 
                            <SidebarItem key = { index } item = { item }/>) } 
                { group === "BUS" && businessItems.map((item, index) => 
                            < SidebarItem key = { index } item = { item }/>) } 
                        
            </div>) }