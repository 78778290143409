import "../../../style/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { base_url } from "../../../api/Axios";
const CustomerDataTable = () => {
  const [customerListData, setCustomerListData] = useState([]);
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [currentURL, setCurrentURL] = useState("");
  const [action, setAction] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const { group } = useContext(AuthContext);
  useEffect(() => {
    (async () => {
      const response = await axios.get(`${base_url}customers?page_size=all`, {
        headers: { Authorization: `Bearer ${token.token}` },
      });
      setCustomerListData(response.data.results);
    })();
  }, []);
  let i = 0;
  const listOfCustomers = customerListData.map((customer) => {
    return {
      id: Number(++i),
      phone: customer.phone,
      first_name: customer.first_name,
      middle_name: customer.middle_name,
      last_name: customer.last_name,
      url: customer.url,
      account_no: customer.account_no,
      gender: customer.gender,
      dob: customer.dob,
      referral_code: customer.referral_code,
      id_number: customer.id_number,
      id_scan: customer.id_scan,
      id_type: customer.id_type,
      photo: customer.photo,
      address: customer.address,
    };
  });

  const userColumns = [
    {
      field: "id",
      headerName: "Phone Number",
      width: 130,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.phone}</div>;
      },
    },
    {
      field: "first_name",
      headerName: "First Name",
      width: 130,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.first_name}</div>
        );
      },
    },
    {
      field: "middle_name",
      headerName: "Middle Name",
      width: 130,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.middle_name}</div>
        );
      },
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 130,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.last_name}</div>;
      },
    },
    {
      field: "account_no",
      headerName: "Account Number",
      width: 130,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.account_no}</div>
        );
      },
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 80,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.gender}</div>;
      },
    },
    {
      field: "referral_code",
      headerName: "Referral Code",
      width: 100,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.referral_code}</div>
        );
      },
    },
  ];
  const detailHandler = (customers) => {
    navigate("/branch/customers/customerDetail", {
      state: { customer: customers },
    });
  };

  const actionHandler = (e) => {
    e.preventDefault();
    console.log("from", action);

    if (currentURL)
      navigate(`/${action}`, {
        state: {
          url: currentURL.split("/")[5],
          action: action,
          category: "customers",
        },
      });
  };
  const actionColumn = [
    {
      field: "Detail",
      headerName: "Detail",
      width: 70,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="viewButton"
              onClick={() => detailHandler(params.row)}
            >
              <VisibilityIcon />
            </div>
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 170,
      renderCell: (params) => {
        return (
          <div className="formInput">
            <select
              className="formInput"
              name="active"
              id="active"
              // value={currentURL}
              onChange={(e) => {
                setAction(e.target.value);
                setCurrentURL(params.row.url);
              }}
              onClick={actionHandler}
              style={{
                backgroundColor: "#E1E1E1",
                color: "#000",
                fontSize: 15,
              }}
            >
              {/* <option>External Actions</option> */}
              <option value="activity_log">Activity Log</option>
              <option value="change_status">Change Status</option>
              <option value="specificStatement">Statement</option>
              <option value="specificSummary">Summary</option>
              {group === "BRA" && (
                <option value="transfers">transactions</option>
              )}
              <option value="operators">operators</option>
            </select>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      {/* <div className="datatableTitle">
        Customers List
        <Link to="/branch/customers/newCustomer" className="link">
          <AddIcon />
        </Link>
      </div> */}
      <DataGrid
        rowHeight={40}
        className="datagrid"
        rows={listOfCustomers}
        columns={userColumns.concat(actionColumn)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        checkboxSelection
        sx={{
          boxShadow: 2,
          // border: 2,
          // borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
      />
    </div>
  );
};

export default CustomerDataTable;
