import "../../../style/list.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import AgentsDataTable from "./AgentsDataTable";
const AgentsList = () => {
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar title={"Agent Accounts"} />
        <AgentsDataTable />
      </div>
    </div>
  );
};

export default AgentsList;
