import "../../../style/detail.scss";
import Box from "@mui/material/Box";
function RedBar() {
  return (
    <Box
      sx={{
        height: 15,
        marginLeft: 50,
      }}
    />
  );
}
const CustomerRegistrationDetail = (props) => {


  const photoName = props.photo ? props.photo.name : null;
  const idBackScanName = props.id_back_scan ? props.id_back_scan.name : null;
  const idScanName = props.id_scan ? props.id_scan.name : null;
  return (
    <div className="multipleInputDetail">
      <Box
        sx={{
          // marginLeft: "25%",
          color:"#000",
          position: "relative",
          display: "grid",
          // flexDirection: "column",
          "& .MuiTextField-root": { width: "35ch" },
        }}
      >
        <div className="detailItem">
          <span className="itemKey">First Name: </span>
          <span className="itemValue">{props.first_name}</span>
        </div>
        
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Middle Name: </span>
          <span className="itemValue">{props.middle_name}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Middle Name: </span>
          <span className="itemValue">{props.last_name}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Phone Number: </span>
          <span className="itemValue">{props.phone}</span>
        </div>
        {props.isTouched && props.phoneError && (
          <div style={{ color: "red" }}>
           {props.phoneError}
          </div>
        )}
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Gender: </span>
          <span className="itemValue">{props.gender}</span>
        </div>
        <RedBar />

        <div className="detailItem">
          <span className="itemKey">Date of Birth: </span>
          <span className="itemValue">{props.dob}</span>
        </div>

        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Photo: </span>

          <span className="itemValue">
            <span className="itemValue">{photoName}</span>
            {/* <a href={`${props.photo}`}>{props.photo}</a> */}
          </span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">ID Type: </span>
          <span className="itemValue">{props.id_type}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">ID Number: </span>
          <span className="itemValue">{props.id_number}</span>
        </div>
      </Box>
      <Box
        sx={{
          // marginLeft: "25%",
          position: "relative",
          display: "grid",
          color:"#000",
          // flexDirection: "column",
          "& .MuiTextField-root": { width: "35ch" },
        }}
      >
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Issued Date: </span>
          <span className="itemValue">{props.issued_date}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Expiry Date: </span>
          <span className="itemValue">{props.expiry_date}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">ID Scan: </span>

          <span className="itemValue">
            <span className="itemValue">{idScanName}</span>
          </span>
        </div>
        <RedBar />

        <div className="detailItem">
          <span className="itemKey">ID Back Scan: </span>
          <span className="itemValue">
            <span className="itemValue">{idBackScanName}</span>
          </span>
        </div>
        <RedBar />

        <div className="detailItem">
          <span className="itemKey">Zone/Sub City: </span>
          <span className="itemValue">{props.zone}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">City: </span>
          <span className="itemValue">{props.city}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Woreda: </span>
          <span className="itemValue">{props.woreda}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Kebele: </span>
          <span className="itemValue">{props.kebele}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">House Number: </span>
          <span className="itemValue">{props.house_no}</span>
        </div>
        <RedBar />
      </Box>
    </div>
  );
};

export default CustomerRegistrationDetail;
