import * as React from "react";
import "./userList.scss";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { green } from "@mui/material/colors";
import Box from "@mui/material/Box";
import CashOut from "./CashOut";
import CashIn from "./CashIn";
import AirTime from "./AirTime";
import BillPay from "./BillPay";
import Pay from "./Pay";
import SendMoney from "./SendMoney";
import Withdraw from "./Withdraw";
import VoucherCashOut from "./VoucherCashOut";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
    backgroundColor= "#E1E1E1"
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 4 }}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    "aria-controls": `action-tabpanel-${index}`,
  };
}

const fabStyle = {
  position: "absolute",
  bottom: 16,
  right: 16,
};

const fabGreenStyle = {
  color: "common.white",
  bgcolor: green[500],
  "&:hover": {
    bgcolor: green[600],
  },
};
const Operation = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <div className="operationContainer" style={{ height: 100 }}>
      <div className="operationHead">Operations</div>

      <Box
        sx={{
          bgcolor: "#E1E1E1",
          width: 1100,
          position: "relative",
          minHeight: 200,
        }}
      >
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="action tabs example"
          >
            <Tab label="Air Time " {...a11yProps(0)} />
            <Tab label="Bill Pay" {...a11yProps(1)} />
            <Tab label="Cash In" {...a11yProps(2)} />
            <Tab label="Cash Out" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
        <div className="OperationBody" style={{ height: "100%" }}>
          {/* <form> */}
          <TabPanel value={value} index={0} dir={theme.direction}>
            <AirTime />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <BillPay />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <CashIn />
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <CashOut />
          </TabPanel>
        </div>
      </Box>
    </div>
  );
};
export default Operation;
