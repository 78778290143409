import "../../../style/new.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import Checkbox from "@mui/material/Checkbox";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { base_url } from "../../../api/Axios";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Container from "@mui/material/Container";
import NewAgentRegistrationDetail from "../Agents/NewAgentRegistrationDetail";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import zones from "../zones.json"
import * as React from "react";
import Box from "@mui/material/Box";
const steps = [
  "Basic Information",
  "ID Information",
  "Owner Address",
  "Organization address",
  "Files-1",
  "Files-2",
  "Submit",
];
function RedBar() {
  return (
    <Box
      sx={{
        height: 30,
        marginLeft: 50,
      }}
    />
  );
}
function RedBar2() {
  return (
    <Box
      sx={{
        height: 20,
        marginLeft: 50,
      }}
    />
  );
}
function RedBar3() {
  return (
    <Box
      sx={{
        height: 10,
        marginLeft: 50,
      }}
    />
  );
}
const NewMerchant = ({ title }) => {
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [phone, setPhoneNumber] = useState("");
  const [business_name, setBusinessName] = useState("");
  const [short_name, setShortName] = useState("");
  const [owner_name, setOwnerName] = useState("");
  const [owner_phone, setOwnerPhone] = useState("");
  const [moa, setMoa] = useState(null);
  const [aoa, setAoa] = useState(null);
  const [license, setLicense] = useState(null);
  const [tin, setTin] = useState(null);
  const [zone, setZone] = useState("");
  const [city, setCity] = useState("");
  const [woreda, setWoreda] = useState("");
  const [kebele, setKebele] = useState("");
  const [house_no, setHouseNumber] = useState("");
  const [specification_location, setSpecificLocation] = useState("");
  const [is_business, setIsBusiness] = useState("");
  const [statusValue, setStatus] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [isTouched, setTouched] = useState(false);
  const [isBusinessValid, setISBusinessNameIsValid] = useState(true);
  const [ownerPhoneError, setOwnerPhoneError] = useState(false);
  const [shortNameErrorMessage, setShortNameError] = useState(false);
  const [no_employee, setNumberOfEmployee] = useState("");
  const [photo, setPhoto] = useState("");
  const [id_type, setIdType] = useState("");
  const [id_number, setIdNumber] = useState("");
  const [issued_date, setIssuedDate] = useState("");
  const [expiry_date, setExpireDate] = useState("");
  const [id_scan, setIdScan] = useState("");
  const [id_back_scan, setIdBackScan] = useState("");
  const [owner_zone, setOwnerZone] = useState("");
  const [owner_city, setOwnerCity] = useState("");
  const [owner_woreda, setOwnerWoreda] = useState("");
  const [owner_kebele, setOwnerKebele] = useState("");
  const [owner_house_no, setOwnerHouseNumber] = useState("");
  const [vat, setVat] = useState("");
  const [contract, setContract] = useState("");
  const [registration, setRegistration] = useState("");
  const [other, setOther] = useState("");
  const [tin_value, setTinValue] = useState("");
  const [photoName, setPhotoName] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isOwnerZoneValid, setIsOwnerZoneValid] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const submitHandler = async (e) => {
    e.preventDefault();
    setTouched(true);
    const enteredMerchantInformation = {
      photo: photo,
      id_type: id_type,
      id_number: id_number,
      issued_date: issued_date,
      expiry_date: expiry_date,
      id_scan: id_scan,
      id_back_scan: id_back_scan,

      owner_zone: owner_zone,
      owner_city: owner_city,
      owner_woreda: owner_woreda,
      owner_kebele: owner_kebele,
      owner_house_no: owner_house_no,
      vat: vat,
      contract: contract,
      registration: registration,
      other: other,
      tin_value: tin_value,

      phone: phone,
      business_name: business_name,
      short_name: short_name,
      owner_name: owner_name,
      owner_phone: owner_phone,
      moa: moa,
      aoa: aoa,
      license: license,
      tin: tin,
      no_employee: no_employee,
      // status: statusValue,
      address: {
        zone: zone,
        city: city,
        woreda: woreda,
        kebele: kebele,
        house_no: house_no,
        specific_location: specification_location,
        is_business: is_business,
      },
    };
    console.log(enteredMerchantInformation);
    try {
      // console.log(enteredMerchantInformation);
      // console.log(token);
      const response = await axios.post(
        `${base_url}merchants`,
        enteredMerchantInformation,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token.token}`,
          },
        }
      );
      setISBusinessNameIsValid(true);
      toast.success("You have successfully register a new merchant!");
      navigate("/branch/merchants");
    } catch (error) {
      console.log(error.response.data);
      // const errorData = error.response.data;
      if (error.response.data.short_name) {
        // console.log(error.response.data.short_name[0]);
        setShortNameError(error.response.data.short_name[0]);
      }
      if (error.response.data.business_name) {
        setISBusinessNameIsValid(false);
      } else if (error.response.data.phone) {
        console.log(error.response.data.phone);
        setIsPhoneValid(false);
        setPhoneError(error.response.data.phone[0])
      } else if (error.response.data.owner_phone) {
        setOwnerPhoneError(error.response.data.owner_phone[0]);
      } else if (error.response.data.owner_zone) {
        setIsOwnerZoneValid(error.response.data.owner_zone[0]);
      }
    }
  };
  const myForm = React.useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step: number) => {
    return;
    // return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    // if (!myForm.current.checkValidity()) {
    //   return;
    // }
    let newSkipped = skipped;
    // if (!myForm.current.checkValidity()) {
    //   return;
    // }
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar title={"Add New Merchant"} />
        <div className="top">
          {/* <h1>{title}</h1> */}
        </div>
        <Box sx={{ width: "flex%", marginLeft: 10, marginRight: 10 }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode,
              } = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                {activeStep === 0 && (
                  <div className="multipleInput">
                    <Box
                      sx={{
                        // marginLeft: "25%",
                        position: "relative",
                        display: "grid",
                        // flexDirection: "column",
                        "& .MuiTextField-root": { width: "35ch" },
                      }}
                    >
                      <RedBar />
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          label={"Business Name"}
                          id="margin-normal"
                          type="text"
                          required
                          value={business_name}
                          onChange={(e) => setBusinessName(e.target.value)}
                        />
                      </FormControl>
                      {isTouched && !isBusinessValid && (
                        <p style={{ color: "red" }}>
                          A business with this name is already exists..
                        </p>
                      )}
                      <RedBar />
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          label={"Phone Number"}
                          id="margin-normal"
                          type="text"
                          required
                          value={phone}
                          inputProps={{ maxLength: 13 }}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                      </FormControl>
                      {isTouched && !isPhoneValid && (
                        <p style={{ color: "red" }}>
                          {phoneError}
                        </p>
                      )}
                      <RedBar />
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          label={"Owner/Manager Name"}
                          id="margin-normal"
                          type="text"
                          required
                          value={owner_name}
                          onChange={(e) => setOwnerName(e.target.value)}
                        />
                      </FormControl>

                      <RedBar />
                    </Box>
                    <Box
                      sx={{
                        // marginLeft: "25%",
                        position: "relative",
                        display: "grid",
                        // flexDirection: "column",
                        "& .MuiTextField-root": { width: "35ch" },
                      }}
                    >
                      <RedBar />
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          label={"Short Business Name"}
                          id="margin-normal"
                          type="text"
                          required
                          value={short_name}
                          onChange={(e) => setShortName(e.target.value)}
                        />
                      </FormControl>
                      {isTouched && shortNameErrorMessage && (
                        <p style={{ color: "red" }}>{shortNameErrorMessage}</p>
                      )}

                      <RedBar />
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          label={"Owner/Manager Phone Number"}
                          id="margin-normal"
                          type="phone"
                          value={owner_phone}
                          onChange={(e) => setOwnerPhone(e.target.value)}
                        />
                      </FormControl>
                      <RedBar />
                      <InputLabel id="demo-simple-select-helper-label">
                        Photo
                      </InputLabel>
                      <TextField
                        id="margin-normal"
                        // margin="normal"
                        type="file"
                        onChange={(e) => {
                          setPhoto(e.target.files[0]);
                          setPhotoName(e.target.files[0].name);
                        }}
                        // value={photo}
                      />
                      <RedBar />
                    </Box>
                  </div>
                )}
                {activeStep === 1 && (
                  <div className="multipleInput">
                    <Box
                      sx={{
                        // marginLeft: "25%",
                        position: "relative",
                        display: "grid",
                        // flexDirection: "column",
                        "& .MuiTextField-root": { width: "35ch" },
                      }}
                    >
                      {/* <RedBar /> */}
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <InputLabel id="demo-simple-select-helper-label">
                          Owner/Manager ID Type
                        </InputLabel>
                        <Select
                          id="margin-normal"
                          labelId="demo-simple-select-helper-label"
                          label="Owner/Manager ID Type"
                          value={id_type}
                          onChange={(e) => setIdType(e.target.value)}
                        >
                          <MenuItem>Select ID Type</MenuItem>
                          <MenuItem value="NA">National ID</MenuItem>
                          <MenuItem value="DR">Driver License</MenuItem>
                          <MenuItem value="PA">Passport</MenuItem>
                          <MenuItem value="KE">Kebele ID</MenuItem>
                          <MenuItem value="ST">Student ID</MenuItem>
                          <MenuItem value="EM">Employee ID</MenuItem>
                          <MenuItem value="OT">Other</MenuItem>
                        </Select>
                      </FormControl>

                      <RedBar2 />
                      <InputLabel id="demo-simple-select-helper-label">
                        Owner/Manager ID Issued Date
                      </InputLabel>
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          // label={'margin="normal"'}
                          id="margin-normal"
                          type="date"
                          required
                          value={issued_date}
                          onChange={(e) => setIssuedDate(e.target.value)}
                        />
                      </FormControl>
                      <RedBar />
                      <InputLabel id="demo-simple-select-helper-label">
                        Owner/Manager ID Scan
                      </InputLabel>
                      <TextField
                        id="margin-normal"
                        // margin="normal"
                        name="myImage"
                        type="file"
                        onChange={(e) => {
                          setIdScan(e.target.files[0]);
                          // setPhotoName(e.target.files[0].name);
                        }}
                        // value={id_scan}
                      />
                    </Box>
                    <Box
                      sx={{
                        // marginLeft: "25%",
                        position: "relative",
                        display: "grid",
                        // flexDirection: "column",
                        "& .MuiTextField-root": { width: "35ch" },
                      }}
                    >
                      {/* <RedBar2 /> */}
                      <RedBar />
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          label={"Owner/Manager ID Number"}
                          id="margin-normal"
                          type="text"
                          required
                          value={id_number}
                          onChange={(e) => setIdNumber(e.target.value)}
                        />
                      </FormControl>
                      <RedBar />
                      <InputLabel id="demo-simple-select-helper-label">
                        Owner/Manager ID Expire Date
                      </InputLabel>
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          // label={'margin="normal"'}
                          id="margin-normal"
                          type="date"
                          required
                          value={expiry_date}
                          onChange={(e) => setExpireDate(e.target.value)}
                        />
                      </FormControl>

                      <RedBar />
                      <InputLabel id="demo-simple-select-helper-label">
                        Owner/Manager ID Back Scan
                      </InputLabel>
                      <TextField
                        id="margin-normal"
                        // margin="normal"
                        type="file"
                        onChange={(event) => {
                          setIdBackScan(event.target.files[0]);
                        }}
                        // value={photoName}
                      />
                      <RedBar />
                    </Box>
                  </div>
                )}
                {activeStep === 2 && (
                  <div className="multipleInput">
                    <Box
                      sx={{
                        // marginLeft: "25%",
                        position: "relative",
                        display: "grid",
                        // flexDirection: "column",
                        "& .MuiTextField-root": { width: "35ch" },
                      }}
                    >
                      <RedBar />

                      {/* <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          label={"Owner Zone/Sub City"}
                          id="margin-normal"
                          type="text"
                          required
                          value={owner_zone}
                          onChange={(e) => setOwnerZone(e.target.value)}
                        />
                      </FormControl> */}
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <InputLabel id="demo-simple-select-helper-label">
                        Owner Zone/Sub City
                        </InputLabel>
                        <Select
                          id="margin-normal"
                          labelId="demo-simple-select-helper-label"
                          label={"Owner Zone/Sub City"}
                          value={owner_zone}
                          onChange={(e) => setOwnerZone(e.target.value)}
                        >
                          <MenuItem>Select Owner Zone/Sub City</MenuItem>
                          {zones.map((zone)=>{
                            return <MenuItem value={zone.id}>{zone.name}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                      <RedBar />
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          label={"Owner Woreda"}
                          id="margin-normal"
                          type="text"
                          required
                          value={owner_woreda}
                          onChange={(e) => setOwnerWoreda(e.target.value)}
                        />
                      </FormControl>
                      <RedBar />
                    </Box>
                    <Box
                      sx={{
                        // marginLeft: "25%",
                        position: "relative",
                        display: "grid",
                        // flexDirection: "column",
                        "& .MuiTextField-root": { width: "35ch" },
                      }}
                    >
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          label={"Owner City/Town"}
                          id="margin-normal"
                          type="text"
                          value={owner_city}
                          onChange={(e) => setOwnerCity(e.target.value)}
                        />
                      </FormControl>

                      <RedBar />
                      <TextField
                        label={"Owner Kebele"}
                        id="margin-normal"
                        type="text"
                        value={owner_kebele}
                        onChange={(e) => setOwnerKebele(e.target.value)}
                      />
                    </Box>
                    <Box
                      sx={{
                        // marginLeft: "25%",
                        position: "relative",
                        display: "grid",
                        // flexDirection: "column",
                        "& .MuiTextField-root": { width: "35ch" },
                      }}
                    >
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          label={"Owner House Number"}
                          id="margin-normal"
                          type="text"
                          required
                          inputProps={{ maxLength: 10 }}
                          value={owner_house_no}
                          onChange={(e) => setOwnerHouseNumber(e.target.value)}
                        />
                      </FormControl>
                    </Box>
                  </div>
                )}
                {activeStep === 3 && (
                  <div className="multipleInput">
                    <Box
                      sx={{
                        // marginLeft: "25%",
                        position: "relative",
                        display: "grid",
                        // flexDirection: "column",
                        "& .MuiTextField-root": { width: "35ch" },
                      }}
                    >
                      <RedBar />
                      {/* <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          label={"Zone/Sub City"}
                          id="margin-normal"
                          type="text"
                          value={zone}
                          onChange={(e) => setZone(e.target.value)}
                        />
                      </FormControl> */}
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <InputLabel id="demo-simple-select-helper-label">
                        Zone/Sub City
                        </InputLabel>
                        <Select
                          id="margin-normal"
                          labelId="demo-simple-select-helper-label"
                          label={"Zone/Sub City"}
                          value={zone}
                          onChange={(e) => setZone(e.target.value)}
                        >
                          <MenuItem>Select Zone/Sub City</MenuItem>
                          {zones.map((zone)=>{
                            return <MenuItem value={zone.id}>{zone.name}</MenuItem>
                          })}
                        </Select>
                      </FormControl>

                      <RedBar />
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          label={"Woreda"}
                          id="margin-normal"
                          type="text"
                          value={woreda}
                          onChange={(e) => setWoreda(e.target.value)}
                        />
                      </FormControl>
                      <RedBar />
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          label={"House no"}
                          id="margin-normal"
                          type="text"
                          value={house_no}
                          onChange={(e) => setHouseNumber(e.target.value)}
                        />
                      </FormControl>
                    </Box>
                    <Box
                      sx={{
                        // marginLeft: "25%",
                        position: "relative",
                        display: "grid",
                        // flexDirection: "column",
                        "& .MuiTextField-root": { width: "35ch" },
                      }}
                    >
                      {/* <RedBar2 /> */}
                      <RedBar />
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          label={"City/Town"}
                          id="margin-normal"
                          type="text"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </FormControl>
                      <RedBar />
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          label={"Kebele"}
                          id="margin-normal"
                          type="text"
                          required
                          value={kebele}
                          onChange={(e) => setKebele(e.target.value)}
                        />
                      </FormControl>
                      <RedBar />
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          label={"Specific Location"}
                          id="margin-normal"
                          type="text"
                          value={specification_location}
                          onChange={(e) => setSpecificLocation(e.target.value)}
                        />
                      </FormControl>
                    </Box>
                    <Box
                      sx={{
                        // marginLeft: "25%",
                        position: "relative",
                        display: "grid",
                        // flexDirection: "column",
                        "& .MuiTextField-root": { width: "35ch" },
                      }}
                    >
                      {/* <RedBar2 /> */}
                      <RedBar />
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <InputLabel id="demo-simple-select-helper-label">
                          Is Business
                        </InputLabel>
                        <Select
                          id="margin-normal"
                          labelId="demo-simple-select-helper-label"
                          label="Is Business"
                          value={is_business}
                          onChange={(e) => setIsBusiness(true)}
                        >
                          {/* <MenuItem>Is Business</MenuItem> */}
                          <MenuItem value={true}>YES</MenuItem>
                          <MenuItem value={false}>NO</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                )}
                {activeStep === 4 && (
                  <div className="multipleInput">
                    <Box
                      sx={{
                        // marginLeft: "25%",
                        position: "relative",
                        display: "grid",
                        // flexDirection: "column",
                        "& .MuiTextField-root": { width: "35ch" },
                      }}
                    >
                      <RedBar2 />
                      <InputLabel id="demo-simple-select-helper-label">
                        Memorandum of Agreement
                      </InputLabel>
                      <TextField
                        id="margin-normal"
                        // margin="normal"
                        type="file"
                        onChange={(event) => {
                          setMoa(event.target.files[0]);
                        }}
                        // value={photoName}
                      />

                      <RedBar2 />

                      <InputLabel id="demo-simple-select-helper-label">
                        Trade License
                      </InputLabel>
                      <TextField
                        id="margin-normal"
                        // margin="normal"
                        type="file"
                        onChange={(event) => {
                          setLicense(event.target.files[0]);
                        }}
                        // value={photoName}
                      />
                      <RedBar2 />

                      <InputLabel id="demo-simple-select-helper-label">
                        VAT Registration Certificate
                      </InputLabel>
                      <TextField
                        id="margin-normal"
                        // margin="normal"
                        type="file"
                        onChange={(event) => {
                          setVat(event.target.files[0]);
                        }}
                        // value={photoName}
                      />
                    </Box>
                    <Box
                      sx={{
                        // marginLeft: "25%",
                        position: "relative",
                        display: "grid",
                        // flexDirection: "column",
                        "& .MuiTextField-root": { width: "35ch" },
                      }}
                    >
                      <RedBar2 />
                      <InputLabel id="demo-simple-select-helper-label">
                        Article of Association
                      </InputLabel>
                      <TextField
                        id="margin-normal"
                        // margin="normal"
                        type="file"
                        onChange={(event) => {
                          setAoa(event.target.files[0]);
                        }}
                        // value={photoName}
                      />
                      <RedBar2 />
                      <InputLabel id="demo-simple-select-helper-label">
                        TIN Certificate
                      </InputLabel>
                      <TextField
                        id="margin-normal"
                        // margin="normal"
                        type="file"
                        onChange={(event) => {
                          setTin(event.target.files[0]);
                        }}
                        // value={photoName}
                      />
                      <RedBar2 />
                      <InputLabel id="demo-simple-select-helper-label">
                        Contract Agreement
                      </InputLabel>
                      <TextField
                        id="margin-normal"
                        // margin="normal"
                        type="file"
                        onChange={(event) => {
                          setContract(event.target.files[0]);
                        }}
                        // value={photoName}
                      />
                    </Box>
                  </div>
                )}
                {activeStep === 5 && (
                  <div className="multipleInput">
                    <Box
                      sx={{
                        // marginLeft: "25%",
                        position: "relative",
                        display: "grid",
                        // flexDirection: "column",
                        "& .MuiTextField-root": { width: "35ch" },
                      }}
                    >
                      <RedBar />

                      <InputLabel id="demo-simple-select-helper-label">
                        Trade Registration
                      </InputLabel>
                      <TextField
                        id="margin-normal"
                        // margin="normal"
                        type="file"
                        onChange={(event) => {
                          setRegistration(event.target.files[0]);
                        }}
                        // value={photoName}
                      />

                      <RedBar />

                      <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          label={"TIN"}
                          id="margin-normal"
                          type="text"
                          value={tin_value}
                          onChange={(e) => setTinValue(e.target.value)}
                          inputProps={{
                            maxLength: 10, // Set the maximum length here
                          }}
                        />
                      </FormControl>
                      <RedBar />
                    </Box>
                    <Box
                      sx={{
                        // marginLeft: "25%",
                        position: "relative",
                        display: "grid",
                        // flexDirection: "column",
                        "& .MuiTextField-root": { width: "35ch" },
                      }}
                    >
                      {/* <RedBar2 /> */}
                      <RedBar />
                      <InputLabel id="demo-simple-select-helper-label">
                        Other Relevant Document
                      </InputLabel>
                      <TextField
                        id="margin-normal"
                        // margin="normal"
                        type="file"
                        onChange={(event) => {
                          setOther(event.target.files[0]);
                        }}
                      />
                      <RedBar />

                      <FormControl sx={{ m: 0, width: 340 }}>
                        <TextField
                          label={"Number of Employee"}
                          id="margin-normal"
                          type="number"
                          required
                          value={no_employee}
                          onChange={(e) => setNumberOfEmployee(e.target.value)}
                        />
                      </FormControl>
                      <RedBar />
                    </Box>
                  </div>
                )}

                {activeStep === 6 && (
                  <>
                    <NewAgentRegistrationDetail
                      photo={photo}
                      id_type={id_type}
                      id_number={id_number}
                      issued_date={issued_date}
                      expiry_date={expiry_date}
                      id_scan={id_scan}
                      id_back_scan={id_back_scan}
                      owner_zone={owner_zone}
                      owner_city={owner_city}
                      owner_woreda={owner_woreda}
                      owner_kebele={owner_kebele}
                      owner_house_no={owner_house_no}
                      vat={vat}
                      contract={contract}
                      registration={registration}
                      other={other}
                      tin_value={tin_value}
                      phone={phone}
                      business_name={business_name}
                      short_name={short_name}
                      owner_name={owner_name}
                      owner_phone={owner_phone}
                      moa={moa}
                      aoa={aoa}
                      license={license}
                      tin={tin}
                      no_employee={no_employee}
                      zone={zone}
                      city={city}
                      woreda={woreda}
                      kebele={kebele}
                      house_no={house_no}
                      specific_location={specification_location}
                      is_business={is_business}
                      isTouched={isTouched}
                      isBusinessValid={isBusinessValid}
                      shortNameErrorMessage={shortNameErrorMessage}
                      phoneError={phoneError}
                      ownerPhoneError={ownerPhoneError}
                    />
                  </>
                )}
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1, color: "#000" }}
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}
                {activeStep !== steps.length - 1 && (
                  <Button
                    type="submit"
                    onClick={handleNext}
                    sx={{ bgcolor: "#0066cc", color: "#000" }}
                  >
                    Next
                  </Button>
                )}
                {activeStep === steps.length - 1 && (
                  <>
                    <Button
                      onClick={submitHandler}
                      sx={{
                        bgcolor: "#0066cc",
                        color: "#000",
                        marginLeft: 100,
                      }}
                    >
                      Register
                    </Button>
                  </>
                )}
              </Box>
            </React.Fragment>
          )}
        </Box>
      </div>
    </div>
  );
};

export default NewMerchant;
