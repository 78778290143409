import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import PersonIcon from "@mui/icons-material/Person";
// import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { AuthContext } from "../../branch/context/AuthContext";
import { useContext } from "react";
import { Link, Navigate } from "react-router-dom";
import { base_url } from "../../api/Axios";
import axios from "axios";
import Cookies from "universal-cookie";


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function CustomizedMenus() {
  const { token } = useContext(AuthContext);
  const user = token.user;
  const tokenSend = token.token;
  const { dispatch } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const cookies = new Cookies();


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const profileHandler = (e) => {
    e.preventDefault();
    Navigate("/branch/myProfile");
  };
  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        style={{
          background: "#ffcc00",
          fontSize: "17px",
        }}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {user.username}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Link
          to="/myProfile"
          style={{ textDecoration: "none", color: "white" }}
        >
          <MenuItem disableRipple style={{ background: "#ffcc00" }}>
            <PersonIcon />
            My Profile
          </MenuItem>
        </Link>
        <Link
          to="/myAccount"
          style={{
            textDecoration: "none",
            color: "white",
          }}
        >
          <MenuItem
            onClick={handleClose}
            disableRipple
            style={{ background: "#ffcc00", color: "white" }}
          >
            <AccountBoxIcon />
            My Account
          </MenuItem>
        </Link>
        <Divider style={{ background: "#ffcc00" }} sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
                cookies.remove("token");
                localStorage.clear();
                sessionStorage.clear();
                // dispatch({ type: "LOGIN", payload: null } );
                dispatch({ type: "LOGOUT" } );
            const logoutFun = async()=>{
              try {
                const response = await axios.post(`${base_url}/logout/`,    {
                  headers: { Authorization: `Bearer ${tokenSend}` },
                });
                console.log(response.status)
               
                // dispatch({ type: "LOGOUT"});
                
              } catch (error) {
                console.log(error.response.status);
              }
            }
            logoutFun();

          
          }}
          disableRipple
          style={{ background: "#ffcc00", color: "white" }}
        >
          <LogoutIcon />
          Logout
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
