import "../../../style/new.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { base_url } from "../../../api/Axios";
const New = ({ title }) => {
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [username, setUserName] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [id_type, setIdType] = useState("");
  const [id_number, setIdNumber] = useState("");
  const [isUserNameValid, setIsUserNameValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isTouched, setTouched] = useState(false);
  const [id_scan, setIDScan] = useState(null);
  const [role, setRole] = useState("");
  const [photo, setPhoto] = useState(null);
  const submitHandler = async (e) => {
    e.preventDefault();
    setTouched(true);
    const enteredUserInformation = {

      phone,
      first_name,
      last_name,
      id_type,
      id_number,
      id_scan,
      photo,
      role,
    };
    console.log(enteredUserInformation);
    try {
      const result = await axios.post(
        `${base_url}users`,
        enteredUserInformation,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token.token}`,
          },
        }
      );
      // setIsUserNameValid(true);
      // setIsPhoneValid(true);
      toast.success("You have successfully register a new user!");
      navigate("/branch/users");
    } catch (error) {
      console.log(error.response.data);
      if (error.response.data.username[0]) {
        setIsUserNameValid(true);
      }
      if (error.response.data.phone[0]) {
        setIsPhoneValid(error.response.data.phone[0]);
      }
    }
  };
  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar title={title} />
        {/* <div className="top">
          <h1>{title}</h1>
        </div> */}
        <div className="bottom">
          {/* <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
          </div> */}
          <div className="right">
            <form onSubmit={submitHandler}>
              <div className="formInput">
                <label>Phone Number</label>
                <input
                  type="phone"
                  placeholder="Enter phone"
                  required
                  value={phone}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                {isTouched && !isPhoneValid && (
                  <p style={{ color: "red" }}>
                    User with this phone already exists..
                  </p>
                )}
              </div>
              <div className="formInput">
                <label>First Name</label>
                <input
                  type="text"
                  placeholder="Enter first Name"
                  required
                  value={first_name}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="formInput">
                <label>Last Name</label>
                <input
                  type="text"
                  placeholder="Enter Last Name"
                  required
                  value={last_name}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>

              <div className="formInput">
                <label>Role</label>
                <select
                  className="formInput"
                  name="active"
                  id="active"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option>Select Role</option>
                  <option value="USER">User</option>
                  <option value="MANAGER">Manager</option>
                  <option value="TELLER">Teller</option>
                  <option value="CASHIER">Cashier</option>
                </select>
              </div>
              <div className="formInput">
                <label>ID type</label>
                <select
                  className="formInput"
                  name="active"
                  id="active"
                  value={id_type}
                  onChange={(e) => setIdType(e.target.value)}
                >
                  <option>Select ID Type</option>
                  <option value="NA">National ID</option>
                  <option value="PA">Passport</option>
                  <option value="DL">Driver License</option>
                  <option value="KE">Kebele ID</option>
                  <option value="ST">Student ID</option>
                  <option value="EM">Employee ID</option>
                  <option value="OT">Other</option>
                </select>
              </div>

              <div className="formInput">
                <label>ID Number</label>
                <input
                  type="text"
                  placeholder="Enter ID Number "
                  value={id_number}
                  onChange={(e) => setIdNumber(e.target.value)}
                />
              </div>
              <div className="formInput">
                <label htmlFor="file">ID Scan:</label>
                <input
                  type="file"
                  id="file"
                  onChange={(event) => {
                    setIDScan(event.target.files[0]);
                  }}
                />
              </div>
              <div className="formInput">
                <label htmlFor="file">Photo:</label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setPhoto(e.target.files[0])}
                />
              </div>
              <br />
              <button>Register</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
