import { useState, useContext } from "react";
import Axios from "../../../api/Axios";
import { AuthContext } from "../../context/AuthContext";
import "./userList.scss";
import PhoneIcon from "@mui/icons-material/Phone";
import toast from "react-hot-toast";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LockIcon from "@mui/icons-material/Lock";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { base_url } from "../../../api/Axios";
import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Phone from "@mui/icons-material/Phone";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { width } from "@mui/system";

const theme = createTheme();
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};
const CashOut = () => {
  const [to, setTo] = useState("");
  const [amount, setAmount] = useState("");
  const [pin, setPin] = useState("");
  const [isTouched, setIsTouched] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [phoneError, setPhoneError] = useState(false)
  const [pinError, setPinError] = useState("");
  const { token } = useContext(AuthContext);
  const [isValid, setIsValid] = useState(false);
  const [open, setOpen] = useState(false);
  const [transactionDetail, setTransactionDetail] = useState("");

  // console.log(bodyData);
  const submitHandler = async (e) => {
    e.preventDefault();
    setIsTouched(true);
    try {
      const response = await axios.post(
        `${base_url}transfers/cash_out/validate`,
        { to, amount },
        {
          headers: { Authorization: `Bearer ${token.token}` },
        }
      );
      console.log(response.data);
      setTransactionDetail(response.data);
      setIsValid(true);
      setOpen(true);
      setErrorMessage(false);
    } catch (error) {
      console.log(error.response.data);
      if(error.response.data.to){
        setPhoneError(error.response.data.to[0]);
      }
      else if(error.response.data.error){
        setErrorMessage(error.response.data.error.detail);
      }
    
    }
  };
  const ConfirmHandler = async (e) => {
    e.preventDefault();
    setIsTouched(true);
    try {
      const response = await axios.post(
        `${base_url}transfers/cash_out`,
        { to, amount, pin },
        {
          headers: { Authorization: `Bearer ${token.token}` },
        }
      );

      setTo("");
      setAmount("");
      setPin("");
      setErrorMessage(false);
      toast.success(response.data.detail);
      setPinError(false);
      setOpen(false);
    } catch (error) {
      console.log(error.response.data)
      setPinError(error.response.data.error.detail);
    }
  };
  const theme = createTheme();
  const handleClose = () => setOpen(false);
  return (
    <div className="new">
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box component="form" onSubmit={submitHandler} sx={{ mt: 1 }}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="from">From</InputLabel>
                <OutlinedInput
                  id="text"
                  required
                  type="text"
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <PersonOutlineIcon />
                    </InputAdornment>
                  }
                  label="From"
                />
              </FormControl>
              {isTouched && phoneError && (
                <p style={{ color: "red", fontSize: 15, marginLeft: 20 }}>
                Invalid Phone Number
                </p>
              )}
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="amount">Amount</InputLabel>
                <OutlinedInput
                  id="amount"
                  required
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  }
                  label="Amount"
                />
              </FormControl>

              {isTouched && errorMessage && (
                <p style={{ color: "red", fontSize: 15, marginLeft: 20 }}>
                  {errorMessage}
                </p>
              )}
              
              <FormControl fullWidth sx={{ m: 1 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Cash Out
                </Button>
              </FormControl>
            </Box>
          </Box>
        </Container>
        {isValid && (
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <div className="loginConterFirst">
                  <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="xs">
                      <CssBaseline />
                      <Box
                        sx={{
                          marginTop: 2,
                          display: "flex",
                          width: "250px",
                          marginBottom: 6,

                          flexDirection: "column",
                          alignItems: "center",
                          fontSize: 15,
                        }}
                      >
                        <Box noValidate sx={{ mt: 1 }}>
                          <form onSubmit={ConfirmHandler}>
                            <h3
                              className="itemTitle"
                              style={{ textDecoration: "underline" }}
                            >
                               Cash Out From
                            </h3>
                            <div className="detailItem">
                              <span className="itemKey" style={{ fontWeight: 'bold' }}>Name: </span>
                              <span className="itemValue">
                                {transactionDetail.to_name}
                              </span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey" style={{ fontWeight: 'bold' }}>Phone: </span>
                              <span className="itemValue">
                                {transactionDetail.to}
                              </span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey" style={{ fontWeight: 'bold' }}>Amount: </span>
                              <span className="itemValue">
                                {transactionDetail.amount}
                              </span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey" style={{ fontWeight: 'bold' }}>Fee: </span>
                              <span className="itemValue">
                                {transactionDetail.fee}
                              </span>
                            </div>

                            <div className="detailItem">
                              <span className="itemKey" style={{ fontWeight: 'bold' }}>Total: </span>
                              <span className="itemValue">
                                {transactionDetail.amount +
                                  transactionDetail.fee}
                              </span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey" style={{ fontWeight: 'bold' }}>Status: </span>
                              <span className="itemValue">
                                {transactionDetail.status}
                              </span>
                            </div>
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="pin"
                              label="Enter pin "
                              name="pin"
                              type="password"
                              autoComplete="current-pin"
                              autoFocus
                              inputProps={{ maxLength: 4, minLength: 4 }}
                              value={pin}
                              onChange={(e) => setPin(e.target.value)}
                              // style={{
                              //   width: "150px",
                              //   height: 20,
                              //   marginBottom: 20,
                              // }}
                            />
                            {isTouched && pinError && (
                              <p style={{ color: "red" }}>{pinError}</p>
                            )}
                            <Button
                              className="loginButton"
                              type="submit"
                              fullWidth
                              variant="contained"
                              sx={{ mt: 3, mb: 1 }}
                            >
                              Confirm
                            </Button>
                          </form>
                        </Box>
                      </Box>
                    </Container>
                  </ThemeProvider>
                </div>
              </Typography>
            </Box>
          </Modal>
        )}
      </ThemeProvider>
    </div>
  );
};

export default CashOut;
