import "../../../style/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { base_url } from "../../../api/Axios";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const AccountsDataTable = () => {
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const { group } = useContext(AuthContext);
  const [accountListData, setAccountListData] = useState([]);
  const [currentURL, setCurrentURL] = useState("");
  const [action, setAction] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedPhone, setSelectedPhone] = useState("");
  const [selectedProfile, setSelectedProfile] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [registration_start, setRegistrationStart] = useState("");
  const [registration_end, setRegistrationEnd] = useState("");
  const [activation_start, setActivationStart] = useState("");
  const [activation_end, setActivationEnd] = useState("");
  useEffect(() => {
    (async () => {
      const response = await axios.get(`${base_url}accounts?page_size=200`, {
        headers: { Authorization: `Bearer ${token.token}` },
      });
      setAccountListData(response.data.results);
    })();
  }, []);
  const phoneSelectorHandler = (e) => {
    setSelectedPhone(e.target.value.replace("+", "%2B"));
  };
  const filterHandler = async () => {
    const response = await axios.get(
      `${base_url}accounts?group=${selectedGroup}&phone=${selectedPhone}&profile=${selectedProfile}&status=${selectedStatus}&registration_start=${registration_start}&registration_end=${registration_end}&activation_start=${activation_start}&activation_end=${activation_end}&page_size=all`,
      {
        headers: { Authorization: `Bearer ${token.token}` },
      }
    );
    setAccountListData(response.data.results);
  };
  const listOfAccounts = accountListData.map((account) => {
    return {
      // id: Number(++i),
      id: account.account_no,
      balance: account.balance,
      full_name: account.full_name,
      group: account.group,
      level: account.level,
      profile: account.profile,
      phone: account.phone,
      url: account.url,
      status: account.status,
      services: account.services,
    };
  });

  const userColumns = [
    {
      field: "id",
      headerName: "Account Number",
      width: 150,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.id}</div>;
      },
    },
    {
      field: "phone",
      headerName: "Phone Number",
      width: 150,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.phone}</div>;
      },
    },
    {
      field: "full_name",
      headerName: "Full Name",
      width: 150,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.full_name}</div>;
      },
    },

    {
      field: "group",
      headerName: "Group",
      width: 70,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.group}</div>;
      },
    },
    {
      field: "profile",
      headerName: "Profile",
      width: 100,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.profile}</div>;
      },
    },
    {
      field: "level",
      headerName: "Level",
      width: 100,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.level}</div>;
      },
    },
    {
      field: "balance",
      headerName: "Balance",
      width: 70,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.balance}</div>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 80,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`}>
            {params.row.status}
          </div>
        );
      },
    },
  ];
  const actionHandler = (e) => {
    e.preventDefault();
    // console.log("from", action);

    if (currentURL)
      navigate(`/${action}`, {
        state: {
          url: currentURL.split("/")[5],
          action: action,
          category: "accounts",
        },
      });
  };
  const actionColumn = [
    {
      field: "actions",
      headerName: "Actions",
      width: 170,
      renderCell: (params) => {
        return (
          <div className="formInput">
            <select
              className="formInput"
              name="active"
              id="active"
              onChange={(e) => {
                setAction(e.target.value);
                setCurrentURL(params.row.url);
              }}
              onClick={actionHandler}
              style={{
                backgroundColor: "#E1E1E1",
                color: "#000",
                fontSize: 15,
                width: "100%",
                marginTop: 5,
                height: 25,
              }}
            >
              <option>Extra Actions</option>
              <option value="activity_log">Activity Log</option>
              <option value="change_status">Change Status</option>
              <option value="specificStatement">Statement</option>
              <option value="specificSummary">Summary</option>
              {group === "BRA" && (
                <option value="transfers">transactions</option>
              )}
              <option value="operators">operators</option>
            </select>
          </div>
        );
      },
    },
  ];
  const theme = createTheme();
  return (
    <div className="datatable">
      {/* <div className="datatableTitle">List of Accounts</div> */}

      <div className="accountFilter">
        <ThemeProvider theme={theme}>
          <Container component="main">
            <div className="formInput">
              <select
                className="formInput"
                name="active"
                id="active"
                value={selectedGroup}
                onChange={(e) => setSelectedGroup(e.target.value)}
                style={{
                  // backgroundColor: "#ffcc00",
                  color: "#000",
                  fontSize: 15,
                  width: "100%",
                  marginTop: 10,
                  height: 30,
                }}
              >
                <option value="">Select Category</option>
                {group === "BRA" && <option value="CUS">Customer</option>}
                {group === "AGE" && <option value="CUS">Customer</option>}
                {group === "BRA" && <option value="MER">Merchant</option>}
                {group === "BRA" && <option value="BUS">Business</option>}
                {group === "BRA" && <option value="AGE">Agent</option>}
                {group === "AGE" && <option value="AGE">Agent</option>}
                {group === "BRA" && <option value="BRA">MTO Branch</option>}
                <option value="MTO">MTO</option>
                <option value="NEW">Unregistered Customer</option>
              </select>
            </div>
          </Container>
          <Container component="main">
            <div className="formInput">
              <input
                type="phone"
                placeholder="Enter phone"
                required
                // value={selectedPhone}
                onChange={phoneSelectorHandler}
                style={{
                  // backgroundColor: "#ffcc00",
                  color: "#000",
                  fontSize: 15,
                  width: "100%",

                  height: 25,
                  marginTop: 10,
                }}
              />
            </div>
          </Container>

          <Container component="main">
            <div className="formInput">
              <select
                className="formInput"
                name="active"
                id="active"
                value={selectedProfile}
                onChange={(e) => setSelectedProfile(e.target.value)}
                style={{
                  // backgroundColor: "#ffcc00",
                  color: "#000",
                  fontSize: 15,
                  width: "100%",
                  marginTop: 10,
                  height: 30,
                }}
              >
                <option value="">Select Profile</option>
                {group === "BRA" ||
                  (group === "AGE" && <option value="CUS">Customer</option>)}
                {/* {group === "AGE" && <option value="CUS">Customer</option>} */}
                {group === "BRA" && <option value="MER">Merchant</option>}
                {group === "BRA" && (
                  <option value="ENTERPRISE">Business-Enterprise</option>
                )}
                {group === "BRA" && (
                  <option value="UTILITY">Business-Utility</option>
                )}
                {group === "BRA" ||
                  (group === "AGE" && <option value="AGENT">Agent HO</option>)}
                {group === "BRA" ||
                  (group === "AGE" && (
                    <option value="AGGREGATOR">Super Agent</option>
                  ))}
                {group === "BRA" ||
                  (group === "AGE" && (
                    <option value="STORE">Agent Store</option>
                  ))}
                <option value="AGGREGATOR">Sub agent</option>
                <option value="MTO Branch">MTO Branch</option>
                <option value="MTO">MTO</option>
                <option value="NEW">Unregistered Customer</option>
              </select>
            </div>
          </Container>
          <Container component="main">
            <div className="formInput">
              <select
                className="formInput"
                name="active"
                id="active"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
                style={{
                  // backgroundColor: "#ffcc00",
                  color: "#000",
                  fontSize: 15,
                  width: "100%",
                  marginTop: 10,
                  height: 30,
                }}
              >
                <option value="">Select Status</option>
                <option value="CREATED">Limited</option>
                <option value="ACTIVE">Active</option>
                <option value="LOCKED">Locked</option>
                <option value="DISABLED">Disable</option>
                <option value="DELETED">Deleted</option>
              </select>
            </div>
          </Container>
          {/* <Container component="main">
            <div className="formInput">
              <input
                type="date"
                placeholder="Enter phone"
                required
                value={registration_start}
                onChange={(e) => setRegistrationStart(e.target.value)}
              />
            </div>
          </Container> */}

          <Container component="main">
            <FormControl fullWidth sx={{ m: 1 }}>
              <Button
                type="submit"
                onClick={filterHandler}
                fullWidth
                variant="contained"
                // sx={{ mt: 1, mb: 1 }}
              >
                Submit
              </Button>
            </FormControl>
          </Container>
        </ThemeProvider>
      </div>
      <div style={{ height: 700, width: "100%" }}>
        <DataGrid
          rowHeight={40}
          className="datagrid"
          rows={listOfAccounts}
          columns={userColumns.concat(actionColumn)}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          pagination
          checkboxSelection
          sx={{
            boxShadow: 2,
            // border: 2,
            // borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
          }}
          experimentalFeatures={{ newEditingApi: true }}
        />
      </div>
    </div>
  );
};

export default AccountsDataTable;
