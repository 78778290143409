import "../../style/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../../branch/context/AuthContext";
import { useContext } from "react";
import { base_url } from "../../api/Axios";
import * as React from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
const ActivityLogs = () => {
  const [activityLogsData, setActivityLogsData] = useState([]);
  const { token } = useContext(AuthContext);
  const location = useLocation();
  const url = location.state.url;
  const action = location.state.action;
  const category = location.state.category;
  const [pageSize, setPageSize] = useState(10);
  useEffect(() => {
    (async () => {
      console.log(`${base_url}${category}/${url}/${action}?page_size=all`)
      const activityLogs = await axios.get(
        `${base_url}${category}/${url}/${action}?page_size=all`,
        {
          headers: { Authorization: `Bearer ${token.token}` },
        }
      );
      console.log(`${base_url}${category}/${url}/${action}?page_size=all`)
      setActivityLogsData(activityLogs.data);
    })();
  }, []);
  let i = 0;
  console.log(activityLogsData)
  const listOfLogs = activityLogsData.map((activity) => {
    return {
      id: Number(++i),
      reference_id: activity.reference_id,
      user: activity.data ? activity.data.user : null,
      phone: activity.data ? activity.data.phone : null,
      to: activity.data ? activity.data.to : null,
      activity: activity.activity,
      channel: activity.channel,
      description: activity.description,
      // created_at: new Date(activity.created_at).toLocaleString(),
      updated_at: new Date(activity.updated_at).toLocaleString(),
      status: activity.status.toUpperCase(),
    };
  });

  const activityLogsColumn = [
    {
      field: "activity",
      headerName: "Activity",
      width: 150,
    },
    {
      field: "channel",
      headerName: "Channel",
      width: 90,
    },

 

    {
      field: "user",
      headerName: "User",
      width: 80,
    },
    {
      field: "phone",
      headerName: "Phone ",
      width: 150,
    },
    {
      field: "to",
      headerName: "To",
      width: 150,
    },
    {
      field: "updated_at",
      headerName: "updated At",
      width: 200,
    },
    // {
    //   field: "updated_at",
    //   headerName: "Updated At",
    //   width: 180,
    // },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`}>
            {params.row.status}
          </div>
        );
      },
    },
  ];

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar title={"Activity Logs"}/>
        <div className="datatable">
          {/* <div className="datatableTitle">Activity Logs</div> */}
          <DataGrid
            rowHeight={47}
            className="datagrid"
            rows={listOfLogs}
            columns={activityLogsColumn}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20, 50]}
            pagination
            checkboxSelection
            sx={{
              boxShadow: 2,
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ActivityLogs;
