import "./navbar.scss";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { DarkModeContext } from "../../branch/context/darkModeContext";
import { useContext } from "react";
import CustomizedMenus from "./MenuList";
import { AuthContext } from "../../branch/context/AuthContext";
const Navbar = (props) => {
  const { dispatch } = useContext(DarkModeContext);
  const { token } = useContext(AuthContext);
  const user = token.user;
  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="search">
          <input type="text" placeholder="Search..." />
          <SearchOutlinedIcon />
        </div>
        <div style={{ fontSize: 20, color: "#db5e00" }}>{props.title}</div>
        <div className="items">
          {/* <div className="item">
            <LanguageOutlinedIcon className="icon" />
            English
          </div> */}

          {/* <div className="item">
            <DarkModeOutlinedIcon
              className="icon"
              onClick={() => dispatch({ type: "TOGGLE" })}
            />
          </div> */}

          <div className="item">
            <CustomizedMenus />
          </div>
          {/* <div className="item">
            <img src={user.photo} alt="" className="avatar" />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
