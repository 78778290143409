import "../../style/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../branch/context/AuthContext";
import { useContext } from "react";

import Sidebar from './../sidebar/Sidebar';
import Navbar from './../navbar/Navbar';
import { useLocation } from "react-router-dom";
const SearchedResult = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { group } = useContext(AuthContext);
  const searchedUser = location.state.searchedUser;
  const [currentURL, setCurrentURL] = useState("");
  const [action, setAction] = useState("");
  console.log(searchedUser)
  const listOfAccounts = searchedUser.map((account) => {
    return {
      // id: Number(++i),
      id: account.account_no,
      balance: account.balance,
      full_name: account.full_name,
      group: account.group,
      level: account.level,
      profile: account.profile,
      phone: account.phone,
      url: account.url,
      status: account.status,
      services: account.services,
    };
  });

  const userColumns = [
    {
      field: "id",
      headerName: "Account Number",
      width: 150,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.id}</div>;
      },
    },
    {
      field: "phone",
      headerName: "Phone Number",
      width: 150,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.phone}</div>;
      },
    },
    {
      field: "full_name",
      headerName: "Full Name",
      width: 150,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.full_name}</div>;
      },
    },

    {
      field: "group",
      headerName: "Group",
      width: 70,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.group}</div>;
      },
    },
    {
      field: "profile",
      headerName: "Profile",
      width: 100,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.profile}</div>;
      },
    },
    {
      field: "level",
      headerName: "Level",
      width: 100,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.level}</div>;
      },
    },
    {
      field: "balance",
      headerName: "Balance",
      width: 70,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.balance}</div>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 80,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`}>
            {params.row.status}
          </div>
        );
      },
    },
  ];
  const actionHandler = (e) => {
    e.preventDefault();
    console.log("from", action);

    if (currentURL)
      navigate(`/${action}`, {
        state: {
          url: currentURL.split("/")[5],
          action: action,
          category: "accounts",
        },
      });
  };
  const actionColumn = [
    {
      field: "actions",
      headerName: "Actions",
      width: 170,
      renderCell: (params) => {
        return (
          <div className="formInput">
            <select
              className="formInput"
              name="active"
              id="active"
              onChange={(e) => {
                setAction(e.target.value);
                setCurrentURL(params.row.url);
              }}
              onClick={actionHandler}
              style={{
                backgroundColor: "#ffcc00",
                color: "#000",
                fontSize: 15,
                width: "100%",
                marginTop: 10,
                height: 30,
              }}
            >
              <option>Extra Actions</option>
              <option value="activity_log">Activity Log</option>
              <option value="change_status">Change Status</option>
              <option value="specificStatement">Statement</option>
              <option value="specificSummary">Summary</option>
              {group === "BRA" && (
                <option value="transfers">transactions</option>
              )}
              <option value="operators">operators</option>
            </select>
          </div>
        );
      },
    },
  ];
  return (
    <div className="list">
    <Sidebar />
    <div className="listContainer">
      <Navbar title={"Results"} />
      <div className="datatable">
      {/* <div className="datatableTitle">Searched Results</div> */}

      
      <div style={{ height: 700, width: "100%" }}>
        <DataGrid
          rowHeight={40}
          className="datagrid"
          rows={listOfAccounts}
          columns={userColumns.concat(actionColumn)}
          
          pagination
          checkboxSelection
          sx={{
            boxShadow: 2,
            // border: 2,
            // borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
          }}
          experimentalFeatures={{ newEditingApi: true }}
        />
      </div>
    </div>
    </div>
  </div>
 
  );
};

export default SearchedResult;
