import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Toaster } from "react-hot-toast";
import { DarkModeContextProvider } from "./branch/context/darkModeContext";
// import store from ".branch/store/index";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Provider } from "react-redux";
import { AuthContextProvider } from "./branch/context/AuthContext";
ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <DarkModeContextProvider>
        <Toaster />
        {/* <Provider store={store}> */}
        <App />
        {/* </Provider> */}
      </DarkModeContextProvider>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
