import "../../style/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../../branch/context/AuthContext";
import { useContext } from "react";
import { base_url } from "../../api/Axios";
import * as React from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
const SpecificCommissions = () => {
  const [commissionData, setCommissionData] = useState([]);
  const { token } = useContext(AuthContext);
  const location = useLocation();
  const url = location.state.url;
  const action = location.state.action;
  const category = location.state.category;
  console.log("from user", url, action, category);
  const [pageSize, setPageSize] = useState(10);
  //   console.log(`${base_url}${url}/${action}`);
  // console.log(`${base_url}${category}/${url}/commissions`);

  useEffect(() => {
    (async () => {
      const response = await axios.get(
        `${base_url}${category}/${url}/commissions?page_size=all`,
        {
          headers: { Authorization: `Bearer ${token.token}` },
        }
      );

      setCommissionData(response.data);
    })();
  }, []);
  let i = 0;
  console.log(commissionData)
  const listofCommisions = commissionData.map((commision) => {
    return {
      id: commision.id,
      amount: commision.amount,
      tax: commision.tax,
      process: commision.process,
      description: commision.description,
      updated_at: new Date(commision.updated_at).toLocaleString(),
    };
  });

  const commisionColumn = [
    { field: "id", headerName: "ID", width: 150 },

    {
      field: "amount",
      headerName: "Amount",
      width: 100,
    },
    {
      field: "tax",
      headerName: "Tax",
      width: 120,
    },

    {
      field: "process",
      headerName: "Process",
      width: 110,
    },
    {
      field: "description",
      headerName: "Description",
      width: 110,
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      width: 200,
    },
  ];

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar title={"Commisions"}/>
        <div className="datatable">
          {/* <div className="datatableTitle">Commisions</div> */}
          <DataGrid
            className="datagrid"
            rows={listofCommisions}
            columns={commisionColumn}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20, 50]}
            checkboxSelection
            sx={{
              boxShadow: 2,
              // border: 2,
              // borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SpecificCommissions;
