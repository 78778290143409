import "../../../style/detailAgent.scss";
import Box from "@mui/material/Box";
function RedBar() {
  return (
    <Box
      sx={{
        height: 10,
        // marginLeft: 50,
        color:"#000"
      }}
    />
  );
}
const NewAgentRegistrationDetail = (props) => {
  // console.log(props.id_scan.name);
  const photoName = props.photo ? props.photo.name : null;
  const idBackScanName = props.id_back_scan ? props.id_back_scan.name : null;
  const idScanName = props.id_scan ? props.id_scan.name : null;
  const moa = props.moa ? props.moa.name : null;
  const aoa = props.aoa ? props.aoa.name : null;
  const license = props.license ? props.license.name : null;
  const tin = props.tin ? props.tin.name : null;
  const vat = props.vat ? props.vat.name : null;
  const contract = props.contract ? props.contract.name : null;
  const other = props.other ? props.other.name : null;
  const registration = props.registration ? props.registration.name : null;
  // console.log("hello", props.city);
  return (
    <div className="multipleInputDetailAgent">
      <Box
        sx={{
          // marginLeft: "25%",
          color:"#000",
          position: "relative",
          display: "grid",
          // flexDirection: "column",
          "& .MuiTextField-root": { width: "flex" },
        }}
      >
        <div className="detailItem">
          <span className="itemKey">Business Name: </span>
          <span className="itemValue">{props.business_name}</span>
        </div>
        {props.isTouched && !props.isBusinessValid && (
          <p style={{ color: "red" }}>
            A business with this name is already exists..
          </p>
        )}
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Short Business Name: </span>
          <span className="itemValue">{props.short_name}</span>
        </div>
        {props.isTouched && props.shortNameErrorMessage && (
          <p style={{ color: "red" }}>{props.shortNameErrorMessage}</p>
        )}
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Profile: </span>
          <span className="itemValue">{props.type}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Phone Number: </span>
          <span className="itemValue">{props.phone}</span>
        </div>
        {props.isTouched && props.phoneError && (
          <p style={{ color: "red" }}>{props.phoneError}</p>
        )}
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Photo: </span>
          <span className="itemValue">
            <span className="itemValue">{photoName}</span>
            {/* <a href={`${props.photo}`}>{props.photo}</a> */}
          </span>
        </div>
        <RedBar />

        <div className="detailItem">
          <span className="itemKey">ID Type: </span>
          <span className="itemValue">{props.id_type}</span>
        </div>

        <RedBar />
        <div className="detailItem">
          <span className="itemKey">ID Number: </span>

          <span className="itemValue">
            <span className="itemValue">{props.id_number}</span>
          </span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Issue Date: </span>
          <span className="itemValue">{props.issued_date}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Expiry Date: </span>
          <span className="itemValue">{props.expiry_date}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">ID Number: </span>
          <span className="itemValue">{props.id_number}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">ID Scan: </span>
          <span className="itemValue">{idScanName}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">ID Back Scan: </span>
          <span className="itemValue">{idBackScanName}</span>
        </div>
      </Box>
      <Box
        sx={{
          // marginLeft: "25%",
          position: "relative",
          display: "grid",
          color:"#000",
          // flexDirection: "column",
          "& .MuiTextField-root": { width: "flex" },
        }}
      >
        <div className="detailItem">
          <span className="itemKey">Owner Name: </span>
          <span className="itemValue">{props.owner_name}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Owner Phone: </span>
          <span className="itemValue">{props.owner_phone}</span>
        </div>
        {props.isTouched && props.ownerPhoneError && (
          <p style={{ color: "red" }}>{props.ownerPhoneError}</p>
        )}
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Owner Zone: </span>
          <span className="itemValue">{props.owner_zone}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Owner City: </span>
          <span className="itemValue">{props.owner_city}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Owner Woreda: </span>
          <span className="itemValue">{props.owner_woreda}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Owner Kebele: </span>
          <span className="itemValue">{props.owner_kebele}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Owner House Number: </span>
          <span className="itemValue">{props.owner_house_no}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Memorandum of Agreement: </span>
          <span className="itemValue">{moa}</span>
        </div>
        <RedBar />

        <div className="detailItem">
          <span className="itemKey">Article of Association: </span>
          <span className="itemValue">{aoa}</span>
        </div>

        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Trade License: </span>

          <span className="itemValue">
            <span className="itemValue">{license}</span>
          </span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">TIN Certificate: </span>
          <span className="itemValue">{tin}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">VAT Registration Certificate: </span>
          <span className="itemValue">{vat}</span>
        </div>
      </Box>
      <Box
        sx={{
          // marginLeft: "25%",
          position: "relative",
          display: "grid",
          color:"#000",
          // flexDirection: "column",
          "& .MuiTextField-root": { width: "flex" },
        }}
      >
        <div className="detailItem">
          <span className="itemKey">Contract Agreement: </span>
          <span className="itemValue">{contract}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Trade Registration: </span>
          <span className="itemValue">{registration}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Other Relevant Document: </span>
          <span className="itemValue">{other}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">TIN: </span>
          <span className="itemValue">{props.tin_value}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">ID Scan: </span>

          <span className="itemValue">
            <span className="itemValue">{idScanName}</span>
          </span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Number of Employee: </span>
          <span className="itemValue">
            <span className="itemValue">{props.no_employee}</span>
          </span>
        </div>
        <RedBar />

        <div className="detailItem">
          <span className="itemKey">Zone/Sub City: </span>
          <span className="itemValue">{props.zone}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">City: </span>
          <span className="itemValue">{props.city}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Woreda: </span>
          <span className="itemValue">{props.woreda}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">Kebele: </span>
          <span className="itemValue">{props.kebele}</span>
        </div>
        <RedBar />
        <div className="detailItem">
          <span className="itemKey">House Number: </span>
          <span className="itemValue">{props.house_no}</span>
        </div>
        <RedBar />
      </Box>
    </div>
  );
};

export default NewAgentRegistrationDetail;
