import "../../../style/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { base_url } from "../../../api/Axios";
import * as React from "react";
import { useNavigate } from "react-router-dom";
const Datatable = () => {
  const [userListData, setUserListData] = useState([]);
  const { token } = useContext(AuthContext);
  const { group } = useContext(AuthContext);
  const [currentURL, setCurrentURL] = useState("");
  const [action, setAction] = useState("");
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  useEffect(() => {
    (async () => {
      const response = await axios.get(`${base_url}users?page_size=1000`, {
        headers: { Authorization: `Bearer ${token.token}` },
      });
      setUserListData(response.data.results);
    })();
  }, []);
  const ListOfUsers = userListData.map((user) => {
    return {
      // id: Number(++i),
      id: user.username,
      balance: user.balance,
      first_name: user.first_name,
      group: user.group,
      id_number: user.id_number,
      id_type: user.id_type,
      language: user.language,
      last_name: user.last_name,
      phone: user.phone,
      role: user.role,
      url: user.url,
      status: user.status,
    };
  });

  const userColumns = [
    // { field: "id", headerName: "ID", width: 70 },
    {
      field: "id",
      headerName: "User Name",
      width: 160,
      // renderCell: (params) => {
      //   return <div className={`cellsWithFields `}>{params.row.id}</div>;
      // },
    },
    {
      field: "phone",
      headerName: "Service Number",
      width: 150,
      // renderCell: (params) => {
      //   return <div className={`cellsWithFields `}>{params.row.phone}</div>;
      // },
    },
    {
      field: "first_name",
      headerName: "First Name",
      width: 100,
      // renderCell: (params) => {
      //   return (
      //     <div className={`cellsWithFields `}>{params.row.first_name}</div>
      //   );
      // },
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 100,
      // renderCell: (params) => {
      //   return <div className={`cellsWithFields `}>{params.row.last_name}</div>;
      // },
    },

    // {
    //   field: "group",
    //   headerName: "Group",
    //   width: 100,
    //   renderCell: (params) => {
    //    return <div className={`cellsWithFields `}>{params.row.group}</div>;
    //   },
    // }, 
    {
      field: "role",
      headerName: "Role",
      width: 120,
      // renderCell: (params) => {
      //   return <div className={`cellsWithFields `}>{params.row.role}</div>;
      // },
    },
    // {
    //   field: "balance",
    //   headerName: "Balance",
    //   width: 100,
    //   renderCell: (params) => {
    //     return <div className={`cellsWithFields `}>{params.row.balance}</div>;
    //   },
    // },

    {
      field: "status",
      headerName: "Status",
      width: 80,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`}>
            {params.row.status}
          </div>
        );
      },
    },
  ];
  const actionHandler = (e) => {
    e.preventDefault();
    console.log("from", action);

    if (currentURL)
      navigate(`/${action}`, {
        state: {
          url: currentURL.split("/")[5],
          action: action,
          category: "users",
        },
      });
  };
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="formInput">
            {/* <label>ID type</label> */}
            <select
              className="formInput"
              name="active"
              id="active"
              // value={currentURL}
              onChange={(e) => {
                setAction(e.target.value);
                setCurrentURL(params.row.url);
              }}
              onClick={actionHandler}
              style={{
                "background-color": "#ffcc00",
                color: "#000",
                "font-size": 15,
              }}
            >
              <option>External Actions</option>
              <option value="activity_log">Activity Log</option>
              <option value="change_status">Change Status</option>
              {/* <option value="reset_password">Reset Password</option>
              <option value="reset_pin">Reset PIN</option> */}
            </select>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        User List
        <Link to="/branch/users/new" className="link">
          <AddIcon />
        </Link>
      </div>
      <DataGrid
        rowHeight={40}
        className="datagrid"
        rows={ListOfUsers}
        columns={userColumns}
        // columns={userColumns.concat(actionColumn)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        checkboxSelection
        sx={{
          boxShadow: 2,
          // border: 2,
          // borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
      />
    </div>
  );
};

export default Datatable;
