import { useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
// import "./userList.scss";
import toast from "react-hot-toast";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { base_url } from "../../../api/Axios";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Phone from "@mui/icons-material/Phone";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
const theme = createTheme();
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

const AirTime = () => {
  const [phone, setPhone] = useState("");
  const [amount, setAmount] = useState("");
  const [pin, setPin] = useState("");
  const [isTouched, setIsTouched] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [amountError, setAmountError] = useState(false)
  const { token } = useContext(AuthContext);
  const [pinError, setPinError] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [open, setOpen] = useState(false);
  const [transactionDetail, setTransactionDetail] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsTouched(true);

    try {
      const response = await axios.post(
        `${base_url}transfers/airtime/validate`,
        { phone, amount },
        {
          headers: { Authorization: `Bearer ${token.token}` },
        }
      );
      console.log(response.data);
      setTransactionDetail(response.data);
      setIsValid(true);
      setOpen(true);
    } catch (error) {
      console.log(error.response.data.error.detail);
      // setPinError(error.response.data.error.detail);
      if(error.response.data.phone){
        setPhoneError(error.response.data.phone[0]);
      }
      else if(error.response.data.amount){
        // setPhoneError(false)
        setAmountError(error.response.data.amount);
      } else if(error.response.data){
        // setPhoneError(false)
        setAmountError(error.response.data.error.detail);
      }
     
    }
  };
  // console.log(transactionDetail);
  const confirmHandler = async (e) => {
    e.preventDefault();
    setIsTouched(true);
    try {
      const response = await axios.post(
        `${base_url}transfers/airtime`,
        { phone, amount, pin },
        {
          headers: { Authorization: `Bearer ${token.token}` },
        }
      );
      console.log(response.data);
      toast.success(response.data.detail);
      setAmount("");
      setPhone("");
      setPin("");
      setPhoneError(false);
      setPinError(false);
      setOpen(false);
    } catch (error) {
      console.log(error.response.data);
      setPinError(error.response.data.error.detail);
      setPhoneError(error.response.data.phone[0]);
    }
  };

  const theme = createTheme();

  //modal close
  const handleClose = () => setOpen(false);
  // const hadnleCloseCreateAccount = () => setIsCreateAccount(false);
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              // marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              onSubmit={submitHandler}
              // noValidate
              sx={{ mt: 1 }}
            >
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="phone">Phone</InputLabel>
                <OutlinedInput
                  id="phone"
                  required
                  type="phone"
                  value={phone}
                  inputProps={{ maxLength: 13 }}
                  onChange={(e) => setPhone(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <Phone />
                    </InputAdornment>
                  }
                  label="Phone"
                />
              </FormControl>
              {isTouched && phoneError && (
                <p style={{ color: "red" }}>{phoneError}</p>
              )}

              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="amount">Amount</InputLabel>
                <OutlinedInput
                  id="amount"
                  required
                  type="number"
                  value={amount}
                  // inputProps={{ min: 1, max: 3000 }}
                  onChange={(e) => setAmount(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  }
                  label="Amount"
                />
              </FormControl>
              {isTouched && amountError && (
                <p style={{ color: "red" }}>{amountError}</p>
              )}
              <FormControl fullWidth sx={{ m: 1 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Top Up
                </Button>
              </FormControl>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>

      {isValid && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div className="loginConterFirst">
                <ThemeProvider theme={theme}>
                  <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                      sx={{
                        marginTop: 2,
                        display: "flex",
                        width: "250px",
                        marginBottom: 6,

                        flexDirection: "column",
                        alignItems: "center",
                        fontSize: 15,
                      }}
                    >
                      <Box noValidate sx={{ mt: 1 }}>
                        <form onSubmit={confirmHandler}>
                          {/* <h3
                            className="itemTitle"
                            // style={{ textDecoration: "underline" }}
                          >
                            Transaction Detail
                          </h3> */}
                          <h3
                            className="itemTitle"
                            style={{ textDecoration: "underline" }}
                          >
                            Buy Airtime
                          </h3>
                          <div className="detailItem">
                            <span className="itemKey">Service Number: </span>
                            <span className="itemValue">
                              {transactionDetail.phone}
                            </span>
                          </div>
                          <div className="detailItem">
                            <span className="itemKey">Amount: </span>
                            <span className="itemValue">
                              {transactionDetail.amount}
                            </span>
                          </div>
                          <div className="detailItem">
                            <span className="itemKey">Status: </span>
                            <span className="itemValue">
                              {transactionDetail.status}
                            </span>
                          </div>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="pin"
                            label="pin "
                            name="pin"
                            type="password"
                            autoComplete="current-pin"
                            autoFocus
                            inputProps={{ maxLength: 4, minLength: 4 }}
                            value={pin}
                            onChange={(e) => setPin(e.target.value)}
                          />
                          {isTouched && pinError && (
                            <p style={{ color: "red" }}>{pinError}</p>
                          )}
                          <Button
                            className="loginButton"
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 1 }}
                          >
                            Confirm
                          </Button>
                        </form>
                      </Box>
                    </Box>
                  </Container>
                </ThemeProvider>
              </div>
            </Typography>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default AirTime;
