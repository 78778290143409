import "../../../style/detail.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { useLocation } from "react-router-dom";
import { useState } from "react";
const TransferDetail = () => {
  const location = useLocation();
  const transactionDetail = location.state.transfer;
  // console.log(transactionDetail);
  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar title={"Transaction Detail"} />
        <div className="top">
          <div className="left">
            <div className="editButton">Edit</div>
            <h1 className="title">Transaction Detail</h1>
            <div className="item" style={{ "margin-left": "60px" }}>
              <div className="details">
                {/* <h1 className="itemTitle">{transactionDetail.id}</h1> */}
                <div className="detailItem">
                  <span className="itemKey">Transfer ID:</span>
                  <span className="itemValue">{transactionDetail.id}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Payer:</span>
                  <span className="itemValue">{transactionDetail.payer}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Payer name:</span>
                  <span className="itemValue">
                    {transactionDetail.payer_name}
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Payer Phone:</span>
                  <span className="itemValue">
                    {transactionDetail.payer_phone}
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Payer Account:</span>
                  <span className="itemValue">
                    {transactionDetail.payer_account}
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Payee:</span>
                  <span className="itemValue">{transactionDetail.payee}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Payee Name:</span>
                  <span className="itemValue">
                    {transactionDetail.payee_name}
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Payee Phone:</span>
                  <span className="itemValue">
                    {transactionDetail.payee_phone}
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Payee Account:</span>
                  <span className="itemValue">
                    {transactionDetail.payee_account}
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Process:</span>
                  <span className="itemValue">{transactionDetail.process}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Amount:</span>
                  <span className="itemValue">
                    {transactionDetail.type}
                    {transactionDetail.amount}
                  </span>
                </div>
                {/* <div className="detailItem">
                  <span className="itemKey">Type:</span>
                  <span className="itemValue">{transactionDetail.type}</span>
                </div> */}
                <div className="detailItem">
                  <span className="itemKey">Fee:</span>
                  <span className="itemValue">{transactionDetail.fee}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Balance:</span>
                  <span className="itemValue">{transactionDetail.balance}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Description:</span>
                  <span className="itemValue">
                    {transactionDetail.description}
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Reason:</span>
                  <span className="itemValue">{transactionDetail.reason}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Status:</span>
                  <span className="itemValue">{transactionDetail.status}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Updated At:</span>
                  <span className="itemValue">
                    {transactionDetail.updated_at}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferDetail;
