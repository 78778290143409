import React from "react";
// import "./noMatch.css";
import Button from "@mui/material/Button";
export const NoMatch = () => {
  return (
    <div className="notFound">
      <h1> Page not found (404)!</h1>
      <p>route not found</p>
      {/* <div className="backHome">
        <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} to="/">
          Back to home page
        </Button>
      </div> */}
    </div>
  );
};
