import "../../style/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../../branch/context/AuthContext";
import { useContext } from "react";
import { base_url } from "../../api/Axios";
import * as React from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
const SpecificSummary = () => {
  const [summaryData, setSummaryData] = useState([]);
  const { token } = useContext(AuthContext);
  const location = useLocation();
  const url = location.state.url;
  const action = location.state.action;
  const category = location.state.category;
  const [pageSize, setPageSize] = useState(10);
  useEffect(() => {
    (async () => {
      const response = await axios.get(
        `${base_url}${category}/${url}/summary?page_size=all`,
        {
          headers: { Authorization: `Bearer ${token.token}` },
        }
      );

      setSummaryData(response.data);
    })();
  }, []);
  console.log(summaryData)
  let i = 0;
  const ListOfSummary = summaryData.map((summary) => {
    return {
      id: Number(++i),
      type: summary.type,
      count: summary.count,
      total: summary.total,
    };
  });

  const summaryColumn = [
    {
      field: "type",
      headerName: "Type",
      width: 100,
    },
    {
      field: "count",
      headerName: "Count",
      width: 150,
    },

    {
      field: "total",
      headerName: "Total",
      width: 100,
    },
  ];

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar title="Summary" />
        <div className="datatable">
          {/* <div className="datatableTitle">Summary</div> */}
          <DataGrid
            className="datagrid"
            rows={ListOfSummary}
            columns={summaryColumn}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20, 50]}
            checkboxSelection
            sx={{
              boxShadow: 2,
              // border: 2,
              // borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SpecificSummary;
