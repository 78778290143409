import "../../../style/list.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import TransfersDataTable from "./TransfersDataTable";
const TransferList = () => {
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar title={"Transactions"} />
        <TransfersDataTable />
      </div>
    </div>
  );
};

export default TransferList;
