import "../../../style/list.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import MerchantDataTable from "./MerchantsDataTable";
const MerchantList = () => {
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar title={"Merchant Accounts"} />
        <MerchantDataTable />
      </div>
    </div>
  );
};

export default MerchantList;
