import "../../../style/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { base_url } from "../../../api/Axios";
import { Button } from "@mui/material";
const TransactionsDataTable = () => {
  const [transactionData, setTransactionData] = useState([]);
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    (async () => {
      const response = await axios.get(
        `${base_url}transactions?page_size=1000`,
        {
          headers: { Authorization: `Bearer ${token.token}` },
        }
      );
      setTransactionData(response.data.results);
    })();
  }, []);
  let i = 0;
  const listOfTransaction = transactionData.map((transaction) => {
    return {
      id: transaction.id,
      payer: transaction.payer,
      payee: transaction.payee,
      process: transaction.process,
      amount: transaction.amount,
      url: transaction.url,
      fee: transaction.fee,
      description: transaction.description,
      detail: transaction.detail,
      status: transaction.status,
      status_code: transaction.status_code,
      message: transaction.message,
      created_at: new Date(transaction.created_at).toLocaleString(),
      updated_at: new Date(transaction.updated_at).toLocaleString(),
    };
  });

  const handleApprove = (transaction) => {
    console.log(transaction.url.split("/")[5]);
    navigate(`/branch/transactions/authorizeTransactions`, {
      state: {
        url: transaction.url.split("/")[5],
      },
    });
  };

  const detailHandler = (transfers) => {
    navigate("/branch/transactions/transactionDetail", {
      state: { transfer: transfers },
    });
  };
  const userColumns = [
    {
      field: "id",
      headerName: "Transaction ID",
      width: 120,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.id}</div>;
      },
    },
    {
      field: "payer",
      headerName: "Payer",
      width: 140,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.payer}</div>;
      },
    },

    {
      field: "payee",
      headerName: "Payee",
      width: 120,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.payee}</div>;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 80,
      renderCell: (params) => {
        return <div className={`cellWithType `}>{params.row.amount}</div>;
      },
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 160,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.created_at}</div>
        );
      },
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      width: 160,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.updated_at}</div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`}>
            {params.row.status}
          </div>
        );
      },
    },
  ];

  const actionColumn = [
    {
      field: "Detail",
      headerName: "Detail",
      width: 70,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="viewButton"
              onClick={() => detailHandler(params.row.url)}
            >
              <VisibilityIcon />
            </div>
          </div>
        );
      },
    },
    {
      field: "Approve",
      headerName: "Approve",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="approveTransaction">
            <div onClick={() => handleApprove(params.row)}>
              <Button style={{ color: "white" }}>Approve</Button>
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">Transactions</div>
      <DataGrid
        rowHeight={40}
        className="datagrid"
        rows={listOfTransaction}
        columns={userColumns.concat(actionColumn)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        checkboxSelection
        sx={{
          boxShadow: 2,
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
      />
    </div>
  );
};
export default TransactionsDataTable;
