import "../../../style/list.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import TransactionsDataTable from "./TransactionsDataTable";
const TransactionsList = () => {
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar title={"Transaction need Authorization"} />
        <TransactionsDataTable />
      </div>
    </div>
  );
};

export default TransactionsList;
