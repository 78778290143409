// import "../../../style/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { base_url } from "../../../api/Axios";
const BusinessDataTable = () => {
  const [businessData, setBusinessData] = useState([]);
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [currentURL, setCurrentURL] = useState("");
  const [action, setAction] = useState("");
  const [pageSize, setPageSize] = useState(10);
  useEffect(() => {
    (async () => {
      const response = await axios.get(`${base_url}businesses?page_size=all`, {
        headers: { Authorization: `Bearer ${token.token}` },
      });
      console.log(response.data.results);
      // const usersList = response.data.data.users;
      setBusinessData(response.data.results);
    })();
  }, []);
  // console.log(businessData);
  let i = 0;
  const listOfBusiness = businessData.map((business) => {
    return {
      id: Number(++i),
      merchant_id: business.merchant_id,
      phone: business.phone,
      business_name: business.business_name,
      short_code: business.short_code,
      type: business.type,
      short_name: business.short_name,
      photo: business.photo,
      id_type: business.id_type,
      id_number: business.id_number,
      issued_date: business.issued_date,
      expiry_date: business.expiry_date,
      id_scan: business.id_scan,
      id_back_scan: business.id_back_scan,
      owner_name: business.owner_name,
      owner_phone: business.owner_phone,
      owner_zone: business.owner_zone,
      owner_city: business.owner_city,
      owner_woreda: business.owner_woreda,
      owner_kebele: business.owner_kebele,
      owner_house_no: business.owner_house_no,
      moa: business.moa,
      aoa: business.aoa,
      license: business.license,
      tin: business.tin,
      vat: business.vat,
      contract: business.contract,
      registration: business.registration,
      other: business.other,
      tin_value: business.tin_value,
      no_employee: business.no_employee,
      level: business.level,
      status: business.status,
      nationality: business.nationality,
      address: business.address,
      is_business: business.is_business,
      url: business.url,
    };
  });
  console.log(listOfBusiness);
  const userColumns = [
    // { field: "id", headerName: "ID", width: 70 },
    {
      field: "phone",
      headerName: "Phone Number",
      width: 120,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.phone}</div>;
      },
    },
    {
      field: "business_name",
      headerName: "Business Name",
      width: 150,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.business_name}</div>
        );
      },
    },
    {
      field: "type",
      headerName: "Business Type",
      width: 120,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.type}</div>;
      },
    },
    {
      field: "short_name",
      headerName: "Short Name",
      width: 90,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.short_name}</div>
        );
      },
    },
    {
      field: "owner_name",
      headerName: "Owner Name",
      width: 100,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.owner_name}</div>
        );
      },
    },
    {
      field: "owner_phone",
      headerName: "Owner Phone",
      width: 120,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.owner_phone}</div>
        );
      },
    },
    {
      field: "merchant_id",
      headerName: "Merchant ID",
      width: 100,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.merchant_id}</div>
        );
      },
    },
  ];
  const detailHandler = (businesses) => {
    console.log(businesses);
    navigate("/branch/businesses/businessDetail", {
      state: { business: businesses },
    });
  };

  const actionHandler = (e) => {
    e.preventDefault();
    console.log("from", action);

    if (currentURL)
      navigate(`/${action}`, {
        state: {
          url: currentURL.split("/")[5],
          action: action,
          category: "businesses",
        },
      });
  };
  const actionColumn = [
    {
      field: "Detail",
      headerName: "Detail",
      width: 70,
      renderCell: (params) => {
        return (
            <div
              className="viewButton"
              onClick={() => detailHandler(params.row)}
            >
              <VisibilityIcon />
            </div>
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 160,
      renderCell: (params) => {
        return (
          <div className="formInput">
            <select
              className="formInput"
              name="active"
              id="active"
              // value={currentURL}
              onChange={(e) => {
                setAction(e.target.value);
                setCurrentURL(params.row.url);
              }}
              onClick={actionHandler}
              style={{
                backgroundColor: "#E1E1E1",
                color: "#000",
                fontSize: 15,
              }}
            >
              <option value="activity_log">Activity Log</option>
              <option value="change_status">Change Status</option>
              <option value="specificStatement">Statement</option>
              <option value="specificSummary">Summary</option>
              <option value="transfers">transactions</option>
              <option value="operators">operators</option>
            </select>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      {/* <div className="datatableTitle">
        Business List
        <Link to="/branch/businesses/newBusiness" className="link">
          <AddIcon />
        </Link>
      </div> */}
      <DataGrid
        rowHeight={40}
        className="datagrid"
        rows={listOfBusiness}
        columns={userColumns.concat(actionColumn)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        checkboxSelection
        sx={{
          boxShadow: 1,
          border: 1,
          borderColor: "black",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
      />
    </div>
  );
};

export default BusinessDataTable;
