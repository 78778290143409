import { useState } from "react"
import { Link } from "react-router-dom";
import './sidebarNew.css'

export default function SidebarItem({item}){
    const [open, setOpen] = useState(false)
    // preventDefault(); 
    if(item.childrens){
        return (
            <div className={open ? "sidebar-item open" : "sidebar-item"}>
                <div className="sidebar-title">
                    <span>
                        { item.icon && <i className={item.icon}></i> }
                        {item.title}    
                    </span> 
                    <i className="bi-chevron-down toggle-btn" onClick={() => setOpen(!open)}></i>
                </div>
                <div className="sidebar-content">
                    { item.childrens.map((child, index) => <SidebarItem key={index} item={child} />) }
                </div>
        
            </div>
            
        )
    }else{
        return (
            <Link to = {item.path || "#"} className="sidebar-item plain">
             {/* <a href={item.path || "#"} className="sidebar-item plain"> */}
                { item.icon && <i className={item.icon}></i> }
                {item.title}
            {/* </a> */}
            </Link>
           
        )
    }
}