import "../../../style/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { base_url } from "../../../api/Axios";
const MerchantDataTable = () => {
  const [merchantsDate, setMerchantsDate] = useState([]);
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [currentURL, setCurrentURL] = useState("");
  const [action, setAction] = useState("");
  const [pageSize, setPageSize] = useState(10);
  useEffect(() => {
    (async () => {
      const response = await axios.get(`${base_url}merchants?page_size=1000`, {
        headers: { Authorization: `Bearer ${token.token}` },
      });
      setMerchantsDate(response.data.results);
    })();
  }, []);
  // console.log(listOfMerchants);
  let i = 0;
  const listOfMerchants = merchantsDate.map((merchant) => {
    return {
      // id: Number(++i),
      id: Number(++i),
      merchant_id: merchant.merchant_id,
      business_name: merchant.business_name,
      // short_code: merchant.short_code,
      // type: merchant.type,
      short_name: merchant.short_name,
      phone: merchant.phone,
      photo: merchant.photo,
      id_type: merchant.id_type,
      id_number: merchant.id_number,
      issued_date: merchant.issued_date,
      expiry_date: merchant.expiry_date,
      id_scan: merchant.id_scan,
      id_back_scan: merchant.id_back_scan,
      owner_name: merchant.owner_name,
      owner_phone: merchant.owner_phone,
      owner_zone: merchant.owner_zone,
      owner_city: merchant.owner_city,
      owner_woreda: merchant.owner_woreda,
      owner_kebele: merchant.owner_kebele,
      owner_house_no: merchant.owner_house_no,
      moa: merchant.moa,
      aoa: merchant.aoa,
      license: merchant.license,
      tin: merchant.tin,
      vat: merchant.vat,
      contract: merchant.contract,
      registration: merchant.registration,
      other: merchant.other,
      tin_value: merchant.tin_value,
      no_employee: merchant.no_employee,
      level: merchant.level,
      status: merchant.status,
      address: merchant.address,
      is_business: merchant.is_business,
      url: merchant.url,
    };
  });

  const userColumns = [
    // { field: "id", headerName: "ID", width: 70 },
    {
      field: "merchant_id",
      headerName: "Merchant ID",
      width: 130,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.merchant_id}</div>
        );
      },
    },
    {
      field: "id",
      headerName: "Phone Number",
      width: 130,
      renderCell: (params) => {
        return <div className={`cellsWithFields `}>{params.row.phone}</div>;
      },
    },
    {
      field: "owner_name",
      headerName: "Owner Name",
      width: 150,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.owner_name}</div>
        );
      },
    },
    {
      field: "business_name",
      headerName: "Business Name",
      width: 130,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.business_name}</div>
        );
      },
    },
    {
      field: "short_name",
      headerName: "Short Name",
      width: 130,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.short_name}</div>
        );
      },
    },

    {
      field: "owner_phone",
      headerName: "Owner Phone",
      width: 130,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.owner_phone}</div>
        );
      },
    },
    {
      field: "merchant_id",
      headerName: "Merchant ID",
      width: 110,
      renderCell: (params) => {
        return (
          <div className={`cellsWithFields `}>{params.row.merchant_id}</div>
        );
      },
    },
  ];
  const detailHandler = (merchants) => {
    navigate("/branch/businesses/businessDetail", {
      state: { business: merchants, group: "merchant" },
    });
  };
  const actionHandler = (e) => {
    e.preventDefault();
    console.log("from", action);

    if (currentURL)
      navigate(`/${action}`, {
        state: {
          url: currentURL.split("/")[5],
          action: action,
          category: "merchants",
        },
      });
  };

  const actionColumn = [
    {
      field: "Detail",
      headerName: "Detail",
      width: 70,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="viewButton"
              onClick={() => detailHandler(params.row)}
            >
              <VisibilityIcon />
            </div>
          </div>
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 170,
      renderCell: (params) => {
        return (
          <div className="formInput">
            <select
              className="formInput"
              name="active"
              id="active"
              // value={currentURL}
              onChange={(e) => {
                setAction(e.target.value);
                setCurrentURL(params.row.url);
              }}
              onClick={actionHandler}
              style={{
                backgroundColor: "#E1E1E1",
                color: "#000",
                fontSize: 15,
              }}
            >
              {/* <option>External Actions</option> */}
              <option value="activity_log">Activity Log</option>
              <option value="change_status">Change Status</option>
              <option value="specificStatement">Statement</option>
              <option value="specificSummary">Summary</option>
              <option value="transfers">transactions</option>
              <option value="operators">operators</option>
            </select>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      {/* <div className="datatableTitle">
        Merchants List
        <Link to="/branch/merchants/newMerchant" className="link">
          <AddIcon />
        </Link>
      </div> */}
      <DataGrid
        rowHeight={40}
        className="datagrid"
        rows={listOfMerchants}
        columns={userColumns.concat(actionColumn)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        checkboxSelection
        sx={{
          boxShadow: 2,
          // border: 2,
          // borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
      />
    </div>
  );
};

export default MerchantDataTable;
